/**
 * Finds the color with the highest average price and the color with the lowest average price
 * based on provided data and common color list.
 *
 * @param {Array} data - An array of objects containing color and bid_price.
 * @returns {Object} An object with properties 'high' and 'low' representing
 * the color with the highest and lowest average prices respectively.
 */
export function findHighestAndLowestAvgColor(data) {
  const commonColors = [
    "beige",
    "black",
    "blue",
    "brown",
    "gold",
    "gray",
    "green",
    "orange",
    "purple",
    "red",
    "silver",
    "silverstone",
    "white",
    "yellow",
  ];

  const colorCounts = {};
  const colorTotalPrices = {};

  if (!data || data.length === 0) {
    const result = {
      high: { color: "N/A", price: 0 },
      low: { color: "N/A", price: 0 },
    };

    return result;
  }

  data.forEach((entry) => {
    if (entry.color && typeof entry.color === "string") {
      const color = entry.color.toLowerCase();
      if (commonColors.includes(color)) {
        colorCounts[color] = (colorCounts[color] || 0) + 1;
        colorTotalPrices[color] =
          (colorTotalPrices[color] || 0) + entry.bid_price;
      } else {
        colorCounts["other"] = (colorCounts["other"] || 0) + 1;
        colorTotalPrices["other"] =
          (colorTotalPrices["other"] || 0) + entry.bid_price;
      }
    } else {
      colorCounts["other"] = (colorCounts["other"] || 0) + 1;
      colorTotalPrices["other"] =
        (colorTotalPrices["other"] || 0) + entry.bid_price;
    }
  });

  const colorAvgPrices = {};

  for (const color in colorCounts) {
    colorAvgPrices[color] = colorTotalPrices[color] / colorCounts[color];
  }

  const highestAvgColor = Object.keys(colorAvgPrices).reduce((a, b) =>
    colorAvgPrices[a] > colorAvgPrices[b] ? a : b
  );

  const lowestAvgColor = Object.keys(colorAvgPrices).reduce((a, b) =>
    colorAvgPrices[a] < colorAvgPrices[b] ? a : b
  );

  const result = {
    high: {
      color: highestAvgColor,
      price: Math.round(colorAvgPrices[highestAvgColor]),
    },
    low: {
      color: lowestAvgColor,
      price: Math.round(colorAvgPrices[lowestAvgColor]),
    },
  };

  return result;
}
