import { Divider, Heading, Stack } from "@chakra-ui/react";
import React from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
  ReferenceLine,
  ReferenceArea,
  // Label,
} from "recharts";
import { tooltipStyles } from "./ChartFormats/tooltipStyle";

export default function AverageMileageVsYearAreaChart(
  props,
  includeReserveNotMet
) {
  // Whether to include RNM auction values or not
  includeReserveNotMet = includeReserveNotMet || true;

  // Get the current year
  const currentYear = parseInt(new Date().getFullYear());

  // Get minimum year from the data
  const minimumYear = Math.min(
    ...props.data
      .map((item) => parseInt(item.auction_year))
      .filter((year) => !isNaN(year))
  );

  // Calculate the average mileage per year
  const averageMileages = props.data.reduce((accumulator, currentValue) => {
    const year = currentValue.auction_year;
    const mileage = currentValue.miles;
    const sold = currentValue.sold;

    if (!accumulator[year]) {
      accumulator[year] = { sum: 0, count: 0 };
    }

    if (!includeReserveNotMet) {
      if (sold === "True") {
        accumulator[year].sum += mileage;
        accumulator[year].count += 1;
      }
    } else {
      accumulator[year].sum += mileage;
      accumulator[year].count += 1;
    }

    return accumulator;
  }, {});

  // get averages
  const averageData = Object.keys(averageMileages).map((year) => {
    return {
      auction_year: year,
      averageMileage: Math.round(
        averageMileages[year].sum / averageMileages[year].count
      ),
    };
  });

  // Find the last available year in the data
  const lastYearInData = Math.max(
    ...averageData.map((item) => parseInt(item.auction_year))
  );

  // Create custom tooltip
  const formatTooltip = (value) => {
    return `${value.toLocaleString(undefined, { maximumFractionDigits: 2 })}`;
  };

  const CustomTooltip = ({ active, payload, label }) => {
    const averageMileage = payload[0];

    if (active) {
      return (
        <Stack className="custom-tooltip">
          <Heading size="md" color="#D3A346">{`Year: ${label}`}</Heading>
          <Divider borderColor="gray.400" />
          <Heading size="md" color={props.color}>{`Avg Mileage: ${formatTooltip(
            averageMileage.value
          )}`}</Heading>
        </Stack>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer
      width="100%"
      height={props.height ? props.height : 350}
    >
      <AreaChart
        data={averageData}
        margin={{
          top: 20,
          right: 15,
          bottom: 10,
          left: 30,
        }}
      >
        <defs>
          <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={props.color} stopOpacity={0.8} />
            <stop offset="95%" stopColor={props.color} stopOpacity={0} />
          </linearGradient>
        </defs>
        <defs>
          <linearGradient id="grayColorGradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#747070" stopOpacity={0.8} />{" "}
            <stop offset="95%" stopColor="#747070" stopOpacity={0} />{" "}
          </linearGradient>
        </defs>

        <XAxis
          dataKey="auction_year"
          stroke={props.labelColor}
          interval={0}
          tick={{ fontSize: 12 }}
          dy={5}
          angle={-45} // Rotate the x-axis labels
          textAnchor="end" // Align the rotated labels
          tickCount={currentYear + 1 - minimumYear}
          type="number"
          domain={[minimumYear, currentYear]}
        ></XAxis>
        <YAxis
          dataKey="averageMileage"
          stroke={props.labelColor}
          tickFormatter={formatTooltip}
        ></YAxis>
        <CartesianGrid vertical={false} strokeDasharray="3 3" />
        <Tooltip content={<CustomTooltip />} wrapperStyle={tooltipStyles} />
        <Area
          type="monotone"
          dataKey="averageMileage"
          stroke={props.color}
          fill="url(#colorGradient)"
          strokeWidth={3}
          isAnimationActive={false}
          dot={{
            fill: "#D3A346", // Change the color of the dots to yellow
            stroke: "#D3A346", // Change the outline color of the dots to yellow
            strokeWidth: 4,
            r: 2,
          }}
          activeDot={{
            fill: "#D3A346", // Change the color of the dots to yellow
            stroke: "#D3A346", // Change the outline color of the dots to yellow
            r: 6,
          }}
        />
        {lastYearInData !== currentYear ? (
          <>
            <ReferenceLine
              segment={[
                {
                  x: lastYearInData,
                  y: averageData.slice(-1)[0].averageMileage,
                },
                { x: currentYear, y: averageData.slice(-1)[0].averageMileage },
              ]}
              stroke={props.color}
              strokeWidth={3}
              // label={
              //   <Label
              //     value="NO SALES"
              //     position="bottom"
              //     style={{ fill: props.color, fontSize: "80%" }}
              //   />
              // }
              strokeDasharray="3 3" // Optional: Dashed line style
            />
            <ReferenceArea
              fill="url(#grayColorGradient)"
              y2={averageData.slice(-1)[0].averageMileage}
              x1={lastYearInData}
              x2={currentYear}
            />
          </>
        ) : null}
      </AreaChart>
    </ResponsiveContainer>
  );
}
