import React, { useEffect, useState } from "react";
import {
  Box,
  Select,
  Button,
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderMark,
  Heading,
  Text,
  useToast,
} from "@chakra-ui/react";
import { getSupabaseClient } from "./Utils/getSupabaseClient";
import { tableName } from "./Config/dbConfig";
import { vehicleMakeAndModels } from "./SoldVehicleInfo/vehicleModels";
import { LoadingScreen } from "./Common/LoadingScreen";
import { calculateAveragePriceAndMileage } from "./Utils/calculateAveragePriceAndMileage";
import { calculateYearlyDifference } from "./Utils/calculateYearlyDifference";
import { findHighestAndLowestAvgColor } from "./Utils/findHighestAndLowestAvgColor";
import { findHighestAndLowestAvgModelYear } from "./Utils/findHighestAndLowestAvgModelYear";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

// extract vehicle makes from dict
const VEHICLE_MAKES = Object.keys(vehicleMakeAndModels);
const INITIAL_YEAR = 1900;
const CURRENT_YEAR = parseInt(new Date().getFullYear());

const UserFilterVehicleData = ({
  finalFilteredData,
  setFinalFilteredData,
  setModelData,
}) => {
  // eslint-disable-next-line
  const [sliderKey, setSliderKey] = useState(0); // needed for resetting range slider

  // For query string usage
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const makeQueryString = searchParams.get("make");
  const modelQueryString = searchParams.get("model");

  // vehicle related hooks
  const [isLoading, setIsLoading] = useState(true);
  const [yearLow, setYearLow] = useState(INITIAL_YEAR);
  const [yearHigh, setYearHigh] = useState(CURRENT_YEAR);
  const [selectedYearLow, setSelectedYearLow] = useState(INITIAL_YEAR);
  const [selectedYearHigh, setSelectedYearHigh] = useState(CURRENT_YEAR);
  const [selectedMake, setSelectedMake] = useState(makeQueryString || "");
  const [selectedModel, setSelectedModel] = useState(modelQueryString || "");
  const [availableModels, setAvailableModels] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const headerAlignment = finalFilteredData.length > 0 ? "left" : "center";
  const toast = useToast();

  const handleEffect = async () => {
    if (makeQueryString && modelQueryString) {
      const models = vehicleMakeAndModels[selectedMake];

      const { data, error } = await fetchVehicleData(
        selectedMake,
        selectedModel
      );
      if (error) {
        toast({
          title: "Error loading vehicle data. Please try again.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } else if (models?.length > 0 && [...models].includes(selectedModel)) {
        setAvailableModels([...models].sort());

        let availableYears = new Set(
          data
            .filter((item) => item.model === selectedModel)
            .map((item) => item.year)
        );
        let minYear = Math.min(...availableYears);
        let maxYear = Math.min(Math.max(...availableYears), CURRENT_YEAR);

        // Update years for slider component
        setYearLow(minYear);
        setSelectedYearLow(minYear);
        setYearHigh(maxYear);
        setSelectedYearHigh(maxYear);

        // Update the key to remount the RangeSlider component
        setSliderKey((prevKey) => prevKey + 1);

        const filteredData = data.filter(
          (item) => item.model === selectedModel
        );

        // Update filtered data to match selected model
        setFilteredData(filteredData);

        // finally, submit data after state updates have taken place
        handleSubmit(filteredData);
      } else {
        setSelectedMake("");
        setSelectedModel("");
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  // Check for query string parameters on page load
  useEffect(() => {
    try {
      handleEffect();
    } catch {
      toast({
        title: "Error loading vehicle data. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    // eslint-disable-next-line
  }, []);

  // Used to find the models associated with a selected make
  const fetchVehicleData = async (selectedMake, selectedModel) => {
    const supabase = getSupabaseClient();
    const { data, error } = await supabase
      .from(tableName)
      .select("*")
      .eq("make", selectedMake)
      .eq("model", selectedModel);
    return { data, error };
  };

  // Handles sliding the smaller year counter
  const handleYearLowChange = (value) => {
    setSelectedYearLow(value);
  };

  // Handles sliding the larger year counter
  const handleYearHighChange = (value) => {
    setSelectedYearHigh(value);
  };

  const handleMakeChange = async (event) => {
    const selectedMake = event.target.value;
    setSelectedMake(selectedMake);
    setSelectedModel(""); // Reset the selected model when make changes
    setAvailableModels([]); // Clear available models

    if (selectedMake) {
      const models = vehicleMakeAndModels[selectedMake];
      console.log(models);
      setAvailableModels([...models].sort()); // Sort models alphabetically
      console.log([...models].sort());
      setYearLow(INITIAL_YEAR);
      setSelectedYearLow(INITIAL_YEAR);
      setYearHigh(CURRENT_YEAR);
      setSelectedYearHigh(CURRENT_YEAR);
    } else {
      setAvailableModels([]); // Clear models when no make is selected
    }
  };

  const handleModelChange = async (event) => {
    setSelectedModel(event.target.value);
    const { data, error } = await fetchVehicleData(
      selectedMake,
      event.target.value
    );
    if (error) {
      toast({
        title: "Error loading vehicle data. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } else {
      let availableYears = new Set(
        data
          .filter((item) => item.model === event.target.value)
          .map((item) => item.year)
      );
      let minYear = Math.min(...availableYears);
      let maxYear = Math.min(Math.max(...availableYears), CURRENT_YEAR);

      // Update years for slider component
      setYearLow(minYear);
      setSelectedYearLow(minYear);
      setYearHigh(maxYear);
      setSelectedYearHigh(maxYear);

      // Update the key to remount the RangeSlider component
      setSliderKey((prevKey) => prevKey + 1);

      // Update filtered data to match selected model
      setFilteredData(data.filter((item) => item.model === event.target.value));
    }
  };

  // Perform all calculations needed for displays on research page
  const handleSubmit = (filteredData) => {
    // Perform your data fetching and filtering based on selected options
    const filteredDataByYear = filteredData.filter(
      (item) => item.year >= selectedYearLow && item.year <= selectedYearHigh
    );

    if (!filteredDataByYear || filteredDataByYear.length === 0) {
      toast({
        title:
          "No specific vehicle data available. Try a different year range.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } else {
      // Capture final filtered data
      setFinalFilteredData(filteredDataByYear);

      // get average price and mileage
      let [currPriceAverage, currMileageAverage] =
        calculateAveragePriceAndMileage(filteredDataByYear, [
          selectedYearLow,
          selectedYearHigh,
        ]);

      // Calculate delta price and mileage YoY
      const currYear = CURRENT_YEAR;
      const deltaPrice = calculateYearlyDifference(
        filteredDataByYear,
        currYear - 1,
        currYear,
        "bid_price"
      );
      const deltaMileage = calculateYearlyDifference(
        filteredDataByYear,
        currYear - 1,
        currYear,
        "miles"
      );

      // Calculate max/min mileage and find avg price for low mileage/high mileage
      let lowMileagePriceAverage = 0;
      let highMileagePriceAverage = 0;
      if (currMileageAverage > 0) {
        const lowMileagePriceSum = filteredDataByYear
          .filter((item) => item.miles < currMileageAverage)
          .map((item) => item.bid_price);

        lowMileagePriceAverage =
          lowMileagePriceSum.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
          ) / lowMileagePriceSum.length;

        const highMileagePriceSum = filteredDataByYear
          .filter((item) => item.miles >= currMileageAverage)
          .map((item) => item.bid_price);

        highMileagePriceAverage =
          highMileagePriceSum.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
          ) / highMileagePriceSum.length;
      }

      // Calculate price per model year and capture highest grossing year
      let bestAndWorstModelYearDict =
        findHighestAndLowestAvgModelYear(filteredDataByYear);

      // Calculate avg price per color and capture highest grossing color
      const bestAndWorstColorDict =
        findHighestAndLowestAvgColor(filteredDataByYear);

      // Used to find image, make, and model
      const sortedData = filteredDataByYear
        .slice()
        .sort((a, b) => b.auction_year - a.auction_year);

      // Now, sortedData contains the array sorted by "auction_year" in descending order
      const firstDataEntry = sortedData[0];

      // Update modelData states
      setModelData((prevModelData) => ({
        ...prevModelData,
        averagePrice: currPriceAverage,
        deltaPrice: deltaPrice,
        deltaMileage: deltaMileage,
        averageMileage: currMileageAverage,
        lowMileagePriceAverage: Math.round(lowMileagePriceAverage),
        highMileagePriceAverage: Math.round(highMileagePriceAverage),
        startYear: selectedYearLow,
        endYear: selectedYearHigh,
        bestAndWorstModelYearDict: bestAndWorstModelYearDict,
        bestAndWorstColorDict: bestAndWorstColorDict,
        vehicleImageUrl: firstDataEntry?.image,
        make: firstDataEntry?.make,
        model: firstDataEntry?.model,
      }));

      // event.preventDefault();
      navigate({
        pathname: "/analytics",
        search:
          "?" +
          createSearchParams({
            make: firstDataEntry?.make,
            model: firstDataEntry?.model,
          }),
      });

      if (isLoading) {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <Box
          px={4}
          py={2}
          w="100%"
          h="100%"
          bg="secondaryBackgroundColor"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="space-evenly"
          borderRadius={12}
          borderWidth={1}
          borderColor="borderColor"
        >
          <Box mb={4} w="100%">
            <Heading
              textAlign={{ base: "center", lg: `${headerAlignment}` }}
              mb={4}
              size={{ base: "md", md: "lg" }}
            >
              Filter Vehicles
            </Heading>
            <Select
              placeholder="Select Make"
              value={selectedMake}
              onChange={handleMakeChange}
              borderColor="borderColor"
            >
              {VEHICLE_MAKES.map((make) => (
                <option key={make} value={make}>
                  {make?.toUpperCase()}
                </option>
              ))}
            </Select>
          </Box>
          <Box mb={4} w="100%">
            <Select
              placeholder="Select Model"
              value={selectedModel}
              onChange={handleModelChange}
              borderColor="borderColor"
              isDisabled={availableModels.length === 0} // Disable dropdown when availableModels is empty
            >
              {availableModels.map((model) => (
                <option key={model} value={model}>
                  {model?.toUpperCase()}
                </option>
              ))}
            </Select>
          </Box>
          <Box mb={6}>
            <Text color="gray.500" fontSize="md">
              Select Years
            </Text>
          </Box>
          <Box
            mb={5}
            mt={2}
            w="70%"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <RangeSlider
              isDisabled={selectedModel === ""}
              aria-label="Select year range"
              defaultValue={[selectedYearLow, selectedYearHigh]}
              value={[selectedYearLow, selectedYearHigh]}
              min={yearLow}
              max={yearHigh}
              step={1}
              onChange={(values) => {
                handleYearLowChange(values[0]);
                handleYearHighChange(values[1]);
              }}
            >
              <RangeSliderMark
                value={selectedYearLow}
                textAlign="center"
                bg="red.500"
                color="white"
                mt={{ base: "-9", "2xl": "-10" }}
                ml={{ base: "-10", "2xl": "-12" }}
                w={{ base: "10", "2xl": "12" }}
                fontSize={{ base: 14, "2xl": 16 }}
                borderRadius={4}
              >
                {selectedYearLow}
              </RangeSliderMark>
              <RangeSliderMark
                value={selectedYearHigh}
                textAlign="center"
                bg="red.500"
                color="white"
                mt={{ base: "-9", "2xl": "-10" }}
                ml={0}
                w={{ base: "10", "2xl": "12" }}
                fontSize={{ base: 14, "2xl": 16 }}
                borderRadius={4}
              >
                {selectedYearHigh}
              </RangeSliderMark>
              <RangeSliderTrack>
                <RangeSliderFilledTrack bg="red.500" />
              </RangeSliderTrack>
              <RangeSliderThumb index={0} />
              <RangeSliderThumb index={1} />
            </RangeSlider>
          </Box>
          <Button
            onClick={() => handleSubmit(filteredData)}
            bg="red.500"
            color="white"
            colorScheme={"red"}
            mb={1}
          >
            Submit
          </Button>
        </Box>
      )}
    </>
  );
};

export default UserFilterVehicleData;
