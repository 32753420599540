import { Divider, Heading, Stack } from "@chakra-ui/react";
import React from "react";
import { tooltipStyles } from "./ChartFormats/tooltipStyle";
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ZAxis,
  Label,
} from "recharts";

export default function PriceVsMileageScatterPlot(props) {
  // format price to $140k or $1.4M
  const currencyLabelFormatter = (value) => {
    if (value >= 1000000) {
      return "$" + (value / 1000000).toFixed(1) + "M";
    } else {
      return "$" + parseInt(value / 1000) + "k";
    }
  };

  const mileageLabelFormatter = (value) => {
    return (parseInt(value) / 1000).toLocaleString() + "k";
  };

  // Create custom tooltip
  const formatTooltip = (value) => {
    return `$${value.toLocaleString()}`;
  };

  // Preprocess data to replace null mileage values with 0
  const processedData = props.data.map((row) => ({
    ...row,
    miles: row.miles !== null ? row.miles : 0,
  }));

  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      const mileage = payload[0].value;
      const price = payload[1].value;

      return (
        <Stack className="custom-tooltip">
          <Heading size="md" color="#D3A346">{`Price: ${formatTooltip(
            price
          )}`}</Heading>
          <Divider borderColor="gray.400" />
          <Heading
            size="md"
            color={props.color}
          >{`Mileage: ${mileage.toLocaleString()}`}</Heading>
        </Stack>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer
      width="100%"
      height={props.height ? props.height : 350}
    >
      <ScatterChart
        data={processedData}
        margin={{
          top: 20,
          right: 15,
          bottom: 15,
          left: 15,
        }}
      >
        <XAxis
          name="Miles"
          dataKey="miles"
          stroke={props.labelColor}
          interval={0}
          tick={{ fontSize: 12 }}
          dy={5}
          tickFormatter={(value) => mileageLabelFormatter(value)}
          type="number"
        >
          <Label
            fontSize="12"
            fontWeight="bold"
            value="Miles"
            fill={props.labelColor}
            dy={22}
            dx={0}
          />
        </XAxis>
        <YAxis
          type="number"
          tickFormatter={(value) => currencyLabelFormatter(value)}
          name="Price"
          dataKey="bid_price"
          stroke={props.labelColor}
        ></YAxis>
        <ZAxis range={[100, 101]} />
        <CartesianGrid vertical={false} stroke="#DDD" strokeDasharray="3 3" />
        <Tooltip
          formatter={formatTooltip}
          content={<CustomTooltip />}
          wrapperStyle={tooltipStyles}
        />
        <Scatter
          name="Price"
          dataKey="bid_price"
          isAnimationActive={false}
          fill="#D3A346"
          r={8}
        />
      </ScatterChart>
    </ResponsiveContainer>
  );
}
