/**
 * Get vehicle data based on given url from json file.
 *
 * @param {string} url - Url of the vehicle.
 * @param {Function} setVehicleData - Function to set single vehicle data
 */

export default function getSingleVehicleDataFromJSON({
  initialPrediction,
  setVehicleData,
}) {
  if (Object.keys(initialPrediction).length > 0) {
    setVehicleData((prevVehicleData) => ({
      ...prevVehicleData,
      year: parseInt(initialPrediction["model-info"][0]),
      make: initialPrediction["model-info"][1],
      model: initialPrediction["model-info"][2],
      mileage: initialPrediction.miles,
      bid_price: initialPrediction.price,
      prediction: initialPrediction.prediction,
      image: initialPrediction.image,
    }));
  }
}
