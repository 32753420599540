import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  config: {
    initialColorMode: "dark",
    useSystemColorMode: false,
  },
  colors: {
    borderColor: "#49505C",
    backgroundColor: "#0F0F10",
    // secondaryBackgroundColor: "#181A1C",
    secondaryBackgroundColor: "#1E2123",
    // redTheme: "#F44336",
    redTheme: "#E53E3E",
    // redChartTheme: "#D34C46",
    redChartTheme: "#E53E3E",
  },
  components: {
    Box: {
      baseStyle: {
        borderWidth: "1px",
        borderRadius: "12px",
        borderColor: "#49505C",
        bg: "#181A1C",
      },
    },
    Text: {
      baseStyle: {
        color: "white", // Set the desired text color for the Text component
      },
    },
    Heading: {
      baseStyle: {
        color: "white", // Set the desired text color for headings
      },
    },
  },
  icons: {
    smallSizeIcon: {
      base: "0.8rem",
      md: "1rem",
      lg: "1.2rem",
      xl: "1.5rem",
    },
    mediumSizeIcon: {
      base: "1rem",
      md: "1.2rem",
      lg: "1.4rem",
      xl: "1.7rem",
    },
    largeSizeIcon: {
      base: "1.5rem",
      md: "1.8rem",
      lg: "2rem",
      xl: "2.5rem",
    },
  },
});

export default theme;
