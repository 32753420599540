// supabaseClient.js

import { createClient } from "@supabase/supabase-js";

let supabase;

export const getSupabaseClient = () => {
  if (!supabase) {
    supabase = createClient(
      "https://ytowafkqsxlmrwbqdgor.supabase.co",
      process.env.REACT_APP_SUPABASE_KEY
    );
  }
  return supabase;
};
