import { getSupabaseClient } from "./getSupabaseClient";
import { tableName } from "../Config/dbConfig";
import { calculateAveragePriceAndMileage } from "./calculateAveragePriceAndMileage";

export default async function getDataFromDatabase({
  vehicleData,
  setVehicleData,
  setAveragePrice,
  setAverageMileage,
  setFilteredData,
}) {
  const fetchVehicleData = async (selectedMake, selectedModel) => {
    const supabase = getSupabaseClient();
    const { data, error } = await supabase
      .from(tableName)
      .select("*")
      .eq("make", selectedMake.toLowerCase())
      .eq("model", selectedModel.toLowerCase());
    return { data, error };
  };

  const { year, make, model } = vehicleData;

  const { data, error } = await fetchVehicleData(make, model);

  if (error || !make || !model) {
    return [];
  }

  if (data.length === 0 && make.toLowerCase().split(" ").length > 1) {
    const makeWithHyphen = make
      .toLowerCase()
      .split(" ")
      .filter((word) => word.trim() !== "")
      .join("-");

    const { data: hyphenatedData } = await fetchVehicleData(
      makeWithHyphen,
      model
    );

    if (hyphenatedData.length > 0) {
      setVehicleData((prevVehicleData) => ({
        ...prevVehicleData,
        make: makeWithHyphen,
      }));
    }
  }

  const [currAveragePrice, currAverageMileage] =
    calculateAveragePriceAndMileage(data, year);

  setAveragePrice(currAveragePrice);
  setAverageMileage(currAverageMileage);
  setFilteredData(data);

  // Return data in the case we plan to use it right away
  return data;
}
