import React from "react";
import { Box, Heading, Spinner, Stack } from "@chakra-ui/react"; // You can replace this with your preferred styling library

export const LoadingScreen = () => {
  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      width="100%"
      height="100%"
      backgroundColor="rgba(0, 0, 0, 0.7)"
      display="flex"
      alignItems="center"
      justifyContent="center"
      zIndex="9999"
    >
      <Stack spacing={4} direction="row">
        <Spinner size="xl" color="white" />
        <Heading> LOADING</Heading>
      </Stack>
    </Box>
  );
};
