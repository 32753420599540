import { Tooltip, Box } from "@chakra-ui/react";
import { FaInfoCircle } from "react-icons/fa";

export const IconToolTip = ({ label }) => {
  return (
    <Tooltip
      bg="gray.100"
      borderWidth="1px"
      borderColor="borderColor"
      borderRadius={4}
      color="backgroundColor"
      label={label}
      textAlign="center"
    >
      <Box alignSelf="center">
        <FaInfoCircle fontSize="1.5rem" />
      </Box>
    </Tooltip>
  );
};
