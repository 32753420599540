import { useAuth0 } from "@auth0/auth0-react";
import { Avatar, Button, Center, Heading, Popover, PopoverBody, PopoverContent, PopoverHeader, PopoverTrigger, Stack, Text, useDisclosure } from "@chakra-ui/react";
import { useRef } from "react";

export const UserProfilePage = () => {
  const { user, logout, isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently } = useAuth0();
  const { onOpen, onClose, isOpen } = useDisclosure()
  const initialFocusRef = useRef()


  const handleDeleteAccount = async () => {
    try {
      await fetch(`https://dev-ha4ty1ze1y4otrpj.us.auth0.com/api/v2/users/${user.sub}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
      });
      logout({ returnTo: window.location.origin });
    } catch (error) {
      console.error(error);
    }
  };

  const LogoutPopover = () => {
    return (
      <Popover initialFocusRef={initialFocusRef} isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <PopoverTrigger>
        <Button color="white" bg="red.500" colorScheme="red">Delete Account</Button>
      </PopoverTrigger>
      <PopoverContent bg="secondaryBackgroundColor">
        <PopoverHeader>Confirm Account Deletion</PopoverHeader>
        <PopoverBody>
          <Text>Are you sure you want to delete your account? This action cannot be undone.</Text>
          <Stack mt={4} align="center" direction="row">
            <Button w="50%" onClick={handleDeleteAccount} color="white" bg="red.500" colorScheme="red" >
              Delete Account
            </Button>
            <Button ref={initialFocusRef} w="50%" onClick={onClose} color="white" bg="gray.500" colorScheme="gray" >
              Cancel
            </Button>
          </Stack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
    )
  }

  return (
    <>
      {isLoading ? (
        <Center bg="backgroundColor" minHeight={{ base:"96vh", md: "90vh", lg: "88vh"}}>Loading...</Center>
      ) : isAuthenticated ? (
        <Center bg="backgroundColor" minHeight={{ base:"96vh", md: "90vh", lg: "88vh"}} flexDirection="column" p={8}>
          <Avatar size="xl" name={user.name} src={user.picture} mb={4} />
          <Heading as="h2" size="lg" mb={2}>
            {user.name}
          </Heading>
          <Text fontSize="xl" mb={4}>
            {user.email}
          </Text>
          <LogoutPopover />
          <Button mt={4} onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>Logout</Button>
        </Center>
      ) : (
        <Center color="white" fontSize="lg" bg="backgroundColor" minHeight={{ base:"96vh", md: "90vh", lg: "88vh"}}>
          <Stack align="center" direction="column">
            <Text>You need to log in to view this page</Text>
            <Button color="white" bg="red.500" colorScheme="red" w="50%" onClick={() => loginWithRedirect()}>
              Login
            </Button>
          </Stack>
          </Center>
      )}
    </>
  );
};

