/*eslint-disable no-unused-vars*/
import { Box, Heading } from "@chakra-ui/react";
import React from "react";

/**
 * ChartWrapper component displays a chart with a heading in a styled container.
 * It acts as a wrapper for individual chart components.
 *
 * @component
 * @param {Object} props - The component props
 * @param {React.ElementType} props.chart - The chart component to be displayed
 * @param {Array} props.data - The data for the chart
 * @param {string} props.color - The color for the chart
 * @param {string} props.labelColor - The color for the chart labels
 * @param {string} props.heading - The heading text to be displayed above the chart
 * @returns {React.Element} The rendered component
 */
export default function ChartWrapper(props) {
  const { chart, data, color, labelColor, height } = props;

  return (
    <Box
      w="100%"
      borderRadius={12}
      py={15}
      px={2}
      bg="secondaryBackgroundColor"
      borderColor="borderColor"
      borderWidth={1}
    >
      <Heading
        textAlign="center"
        mb={2}
        color="#FFFFFF"
        size={{ base: "sm", md: "md" }}
      >
        {props.heading}
      </Heading>
      <props.chart
        data={data}
        color={color}
        labelColor={labelColor}
        height={height}
      />
    </Box>
  );
}
