import { Box, Divider, Heading, Stack, Text } from "@chakra-ui/react";
import { IconToolTip } from "./IconToolTip";
import theme from "../Theme/theme";

export const RoundedStatisticsBlock = ({
  children,
  heading,
  titleTopText,
  topText,
  titleBottomText,
  bottomText,
  textColor,
  bottomTextColor,
  iconSquareTop,
  iconSquareBottom,
  topToolTipLabel,
  bottomToolTipLabel,
}) => {
  return (
    <Box
      borderRadius={12}
      // bg="#1E2125"
      bg="secondaryBackgroundColor"
      w="100%"
      h="100%"
      display="flex"
      borderWidth={1}
      borderColor="borderColor"
      px={{ base: 4, lg: 4 }}
      pt={2}
    >
      <Stack direction="column" w="100%" h="100%">
        <Box pt={2} mb={2} w="100%" h="2.5rem" align="center" justify="center">
          <Heading
            textAlign={{ base: "center", lg: "left" }}
            size={{ base: "md", md: "lg" }}
            color="white"
          >
            {heading}
          </Heading>
          <Divider
            w={{ base: "100%", lg: "100%" }}
            mt={2}
            borderWidth={1}
            borderColor="borderColor"
          />
          <Divider
            w={{ base: "100%", lg: "100%" }}
            mt={2}
            borderWidth={1}
            borderColor="borderColor"
          />
        </Box>
        <Stack
          textAlign="center"
          h="100%"
          w="100%"
          justifyContent="space-evenly"
          spacing={{ base: 4, lg: 3 }}
          pt={6}
          pb={4}
          px={{ base: 0, md: 0 }}
        >
          <Stack
            w="100%"
            spacing={{ base: 6, md: 6 }}
            direction="row"
            justifyContent={{ base: "space-around", lg: "space-between" }}
            bg={{
              base: `linear-gradient(to right, #2C3236, ${theme.colors.secondaryBackgroundColor}, #2C3236)`,
            }}
            borderRadius={12}
            px={{ base: 0, md: 6 }}
          >
            <Box alignSelf="center">{iconSquareTop}</Box>
            <Stack
              alignSelf="space-evenly"
              justifySelf="center"
              mx="auto"
              py={4}
            >
              <Heading size={{ base: "xs", "2xl": "sm" }}>
                {titleTopText}
              </Heading>
              <Text
                color={textColor}
                fontWeight="bold"
                fontSize={{ base: "sm", "2xl": "sm" }}
              >
                {topText}
              </Text>
            </Stack>
            <IconToolTip label={topToolTipLabel} />
          </Stack>
          {/* Bottom Icon and Text */}
          <Stack
            w="100%"
            spacing={{ base: 6, md: 6 }}
            direction="row"
            justifyContent={{ base: "space-around", lg: "space-between" }}
            bg={{
              base: `linear-gradient(to right, #2C3236, ${theme.colors.secondaryBackgroundColor}, #2C3236)`,
            }}
            borderRadius={12}
            px={{ base: 0, md: 6 }}
          >
            <Box alignSelf="center">{iconSquareBottom}</Box>
            <Stack
              alignSelf="space-evenly"
              justifySelf="center"
              mx="auto"
              py={4}
            >
              <Heading size={{ base: "xs", "2xl": "sm" }}>
                {titleBottomText}
              </Heading>
              <Text
                color={bottomTextColor ? bottomTextColor : textColor}
                fontWeight="bold"
                fontSize={{ base: "sm", "2xl": "sm" }}
              >
                {bottomText}
              </Text>
            </Stack>
            <IconToolTip label={bottomToolTipLabel} />
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};
