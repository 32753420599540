export function calculateYearlyDifference(
  data,
  prevYear,
  currYear,
  propertyName
) {
  const getAverage = (array) => {
    if (array.length === 0) {
      return 0;
    }

    const sum = array.reduce((total, value) => total + value, 0);
    const average = sum / array.length;
    return Math.round(average);
  };

  const yearData = (year) =>
    data
      // .filter((item) => (item.auction_year === year) & (item.sold === "True"))
      .filter((item) => item.auction_year === year)
      .map((item) => item[propertyName]);

  const previousYearData = yearData(prevYear);
  const currentYearData = yearData(currYear);

  const previousYearAverage = getAverage(previousYearData);
  const currentYearAverage = getAverage(currentYearData);

  let difference = 0;
  if (currentYearAverage > 0 && previousYearAverage > 0) {
    difference = currentYearAverage - previousYearAverage;
  }

  return difference;
}
