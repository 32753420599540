import { getSupabaseClient } from "./getSupabaseClient";
import { tableName } from "../Config/dbConfig";

/**
 * Filter vehicle data based on make/model of given vehicle.
 *
 * @param {string} url - Url of the vehicle.
 * @param {Function} setSingleVehicleData - Function to set single vehicle data
 */

export default async function getSingleVehicleDataFromDatabase({
  url,
  setVehicleData,
}) {
  const supabase = getSupabaseClient();
  let query = supabase.from(tableName).select("*");
  const { data } = await (url ? query.eq("url", url) : query);

  let vehicleData = [];

  if (data.length > 0) {
    vehicleData = data[0];

    setVehicleData((prevVehicleData) => ({
      ...prevVehicleData,
      year: vehicleData.year,
      make: vehicleData.make,
      model: vehicleData.model,
      mileage: vehicleData.miles,
      bid_price: vehicleData.bid_price,
      prediction: vehicleData.prediction,
      image: vehicleData.image,
    }));
  }

  // Return data just in case we want to use it right away
  return vehicleData;
}
