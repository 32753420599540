import {
  Box,
  Container,
  Link,
  Stack,
  Text,
  useColorModeValue,
  Button,
} from "@chakra-ui/react";
import { MdLocalCafe } from "react-icons/md";

export default function SmallCentered() {
  return (
    <Box
      bg="backgroundColor"
      color={useColorModeValue("gray.700", "gray.200")}
      position={"absolute"}
      width={{ base: "100%", md: "100%", lg: "100%" }}
      maxH={"370px"}
      maxW="100vw"
      overflow="hidden"
      py={{ base: 2 }}
    >
      <Container
        as={Stack}
        maxW={"6xl"}
        py={4}
        spacing={4}
        justify={"center"}
        align={"center"}
      >
        <Stack
          direction={{ base: "column", md: "row" }}
          spacing={{ base: 3, md: 6, lg: 12 }}
          alignItems={"center"}
          textAlign={"center"}
        >
          <Stack
            direction={{ base: "row", md: "row" }}
            spacing={{ base: 4, md: 4 }}
            alignItems={"center"}
          >
            <Text as="b"> Support Us </Text>
            <Link href="https://www.buymeacoffee.com/tropskee" isExternal>
              <Button colorScheme="yellow" leftIcon={<MdLocalCafe />}>
                Buy us a coffee
              </Button>
            </Link>
          </Stack>
          <Stack
            direction={{ base: "row", md: "row" }}
            spacing={{ base: 3, md: 4 }}
            alignItems={"center"}
          >
            <Link href={"/analytics"}>Analytics</Link>
            <Link href={"/about"}>About</Link>
            <Link href={"/contact"}>Contact</Link>
            <Link href={"/privacy"}>Privacy</Link>
          </Stack>
          <Text fontSize={"sm"}>
            © 2023 PredictATrailer.com. All rights reserved
          </Text>
        </Stack>
      </Container>
    </Box>
  );
}
