import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import theme from "./Components/Theme/theme";
import ScrollTop from "./Components/ScrollTop";
import Navbar from "./Components/NavbarSubNav";
import Footer from "./Components/FooterRow";
import Contact from "./Pages/Contact";
import Privacy from "./Pages/Privacy";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import About from "./Pages/About";
import { UserProfilePage } from "./Pages/UserProfile";
import Analytics from "./Pages/Analytics";
import Home from "./Pages/Home";
import ReactGA from "react-ga4";

// Google Analytics
const MEASUREMENT_ID = "G-01DTK4X1TT"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(MEASUREMENT_ID);

function App() {
  const location = useLocation();
  // Fired on every route change
  useEffect(() => {
    // ReactGA.pageview(location.pathname + location.search);
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);

  return (
    <>
      <ChakraProvider theme={theme}>
        <ColorModeScript initialColorMode={theme.config.initialColorMode} />
        <ScrollTop />
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="analytics" element={<Analytics />} />
          <Route path="contact" element={<Contact />} />
          <Route path="about" element={<About />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="profile" element={<UserProfilePage />} />
          {/* <Route path="pricing" element={<Pricing />} /> */}
          {/* <Route path="car-value" element={<MyCarsValue />} /> */}
          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
        <Footer />
      </ChakraProvider>
    </>
  );
}

export default App;
