import { Divider, Heading, Stack } from "@chakra-ui/react";
import React from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
} from "recharts";
import { tooltipStyles } from "./ChartFormats/tooltipStyle";

export default function SoldByColorBarChart({
  data,
  includeReserveNotMet,
  height,
  labelColor,
  color,
}) {
  // Whether to include RNM auction values or not
  includeReserveNotMet = includeReserveNotMet || true;

  // Most common colors
  const commonColors = [
    "beige",
    "black",
    "blue",
    "brown",
    "gold",
    "gray",
    "green",
    "orange",
    "purple",
    "red",
    "silver",
    "silverstone",
    "white",
    "yellow",
  ];

  // format price to $140k or $1.4M
  const currencyLabelFormatter = (value) => {
    if (value >= 1000000) {
      return "$" + (value / 1000000).toFixed(1) + "M";
    } else {
      return "$" + parseInt(value / 1000) + "k";
    }
  };

  /**
   * Count unique colors and accumulate their counts and prices.
   *
   * @param {Array} iterable - List of vehicle data objects.
   * @param {boolean} includeReserveNotMet - Whether to include reserve not met data.
   * @returns {Object} A dictionary with color as keys and corresponding price as values.
   */
  const calculateAveragePrices = (iterable) => {
    // Filter the data based on whether to include reserve not met or not
    let filteredData = iterable;

    if (!includeReserveNotMet) {
      filteredData = iterable.filter((item) => item.sold === "True");
    }

    const colorCounts = {};
    const colorTotalPrices = {};

    filteredData.forEach((entry) => {
      if (entry.color && typeof entry.color === "string") {
        const color = entry.color.toLowerCase();
        if (commonColors.includes(color)) {
          colorCounts[color] = (colorCounts[color] || 0) + 1;
          colorTotalPrices[color] =
            (colorTotalPrices[color] || 0) + entry.bid_price;
        } else {
          colorCounts["other"] = (colorCounts["other"] || 0) + 1;
          colorTotalPrices["other"] =
            (colorTotalPrices["other"] || 0) + entry.bid_price;
        }
      } else {
        colorCounts["other"] = (colorCounts["other"] || 0) + 1;
        colorTotalPrices["other"] =
          (colorTotalPrices["other"] || 0) + entry.bid_price;
      }
    });

    const colorAvgPrices = {};

    for (const color in colorCounts) {
      colorAvgPrices[color] = colorTotalPrices[color] / colorCounts[color];
    }

    return colorAvgPrices;
  };

  // Format to be ingested into chart
  const colors = Object.entries(calculateAveragePrices(data)).map(
    ([color, avgPrice]) => ({
      color: color.slice(0, 1).toUpperCase() + color.slice(1),
      avgPrice: avgPrice,
    })
  );

  // Create custom tooltip
  const formatTooltip = (value) => {
    return `$${Math.round(value)}`;
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      const colorData = payload[0].payload;
      const { avgPrice } = colorData;

      return (
        <Stack className="custom-tooltip">
          <Heading size="md">{`Color: ${label}`}</Heading>
          <Divider borderColor="gray.400" />
          <Heading size="md" color={color}>
            {`Average Price: $${Math.round(avgPrice).toLocaleString()}`}
          </Heading>
        </Stack>
      );
    }

    return null;
  };

  // Define a gradient configuration
  const gradientId = "colorGradient";
  const gradientColorStart = "#D34C46"; // Start color of gradient
  const gradientColorEnd = "#D34C46"; // End color of gradient

  return (
    <ResponsiveContainer width="100%" height={height ? height : 350}>
      <BarChart
        data={colors}
        margin={{
          top: 20,
          right: 15,
          bottom: 18,
          left: 15,
        }}
      >
        <defs>
          {/* Define the linear gradient */}
          <linearGradient id={gradientId} x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor={gradientColorStart} />
            <stop offset="5%" stopColor={gradientColorEnd} />
            <stop offset="100%" stopColor={gradientColorEnd} stopOpacity="0" />
          </linearGradient>
        </defs>
        <XAxis
          name="color"
          dataKey="color"
          stroke={labelColor}
          interval={0}
          dy={5}
          tick={{ fontSize: 12 }}
          angle={-45} // Rotate the x-axis labels
          textAnchor="end" // Align the rotated labels
        />
        <YAxis
          name="avgPrice"
          stroke={labelColor}
          tickFormatter={currencyLabelFormatter}
        />
        <CartesianGrid vertical={false} strokeDasharray="3 3" />
        <Tooltip
          formatter={formatTooltip}
          content={<CustomTooltip />}
          wrapperStyle={tooltipStyles}
        />
        {/* Apply the gradient fill to the Bar */}
        <Bar
          name="Average Price"
          dataKey="avgPrice"
          fill={`url(#${gradientId})`} // Use the gradient
        />
      </BarChart>
    </ResponsiveContainer>
  );
}
