import {
  Box,
  Container,
  Flex,
  Heading,
  SimpleGrid,
  Icon,
  Text,
  Stack,
  HStack,
  VStack,
} from "@chakra-ui/react";
import { MdMemory, MdCloudCircle } from "react-icons/md";
import { FaChartBar, FaSearchDollar, FaRecycle, FaCar } from "react-icons/fa";
import { Helmet, HelmetProvider } from "react-helmet-async";

// const SHINY_OVERLAY = `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='1440' height='1440' preserveAspectRatio='none' viewBox='0 0 1440 1440'%3e%3cg mask='url(%26quot%3b%23SvgjsMask2124%26quot%3b)' fill='none'%3e%3crect width='1440' height='1440' x='0' y='0' fill='url(%23SvgjsLinearGradient2125)'%3e%3c/rect%3e%3cpath d='M0 0L429.77 0L0 494.75z' fill='rgba(255%2c 255%2c 255%2c .1)'%3e%3c/path%3e%3cpath d='M0 494.75L429.77 0L810.87 0L0 926.95z' fill='rgba(255%2c 255%2c 255%2c .075)'%3e%3c/path%3e%3cpath d='M0 926.95L810.87 0L1156.43 0L0 1246.72z' fill='rgba(255%2c 255%2c 255%2c .05)'%3e%3c/path%3e%3cpath d='M0 1246.72L1156.43 0L1317.3000000000002 0L0 1250.52z' fill='rgba(255%2c 255%2c 255%2c .025)'%3e%3c/path%3e%3cpath d='M1440 1440L795.99 1440L1440 826.31z' fill='rgba(0%2c 0%2c 0%2c .1)'%3e%3c/path%3e%3cpath d='M1440 826.31L795.99 1440L531.59 1440L1440 578.5z' fill='rgba(0%2c 0%2c 0%2c .075)'%3e%3c/path%3e%3cpath d='M1440 578.5L531.59 1440L385.24 1440L1440 309z' fill='rgba(0%2c 0%2c 0%2c .05)'%3e%3c/path%3e%3cpath d='M1440 309L385.24 1440L213.95000000000002 1440L1440 159.21z' fill='rgba(0%2c 0%2c 0%2c .025)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask2124'%3e%3crect width='1440' height='1440' fill='white'%3e%3c/rect%3e%3c/mask%3e%3clinearGradient x1='0%25' y1='0%25' x2='100%25' y2='100%25' gradientUnits='userSpaceOnUse' id='SvgjsLinearGradient2125'%3e%3cstop stop-color='rgba(14%2c 42%2c 71%2c 1)' offset='0'%3e%3c/stop%3e%3cstop stop-color='rgba(26%2c 32%2c 44%2c 1)' offset='0.22'%3e%3c/stop%3e%3c/linearGradient%3e%3c/defs%3e%3c/svg%3e")`;

const features = [
  {
    id: "about-fact-1",
    title: "Advanced Machine Learning",
    text: "We employ cutting-edge machine learning algorithms to provide you with accurate and confident price predictions.",
    icon: MdMemory,
    color: "purple.300",
  },
  {
    id: "about-fact-2",
    title: "A Wealth of Auction Insights",
    text: "We leverage a vast database of over 110,000 previously completed auctions, continuously expanding our knowledge to serve you better.",
    icon: MdCloudCircle,
    color: "blue.400",
  },
  {
    id: "about-fact-3",
    title: "Boosting Confidence in Your Decisions",
    text: "An impressive 94% of auction results fall within PredictATrailer's estimated price range, ensuring your peace of mind.",
    icon: FaChartBar,
    color: "orange.400",
  },
  {
    id: "about-fact-4",
    title: "Comprehensive Vehicle Analysis",
    text: "Our model considers an array of vehicle features, including make, model, year, mileage, engine size, cylinders, paint color, and more.",
    icon: FaSearchDollar,
    color: "green.400",
  },
  {
    id: "about-fact-5",
    title: "Automated Model Updates",
    text: "We regularly refresh our machine learning model with the latest auction data, keeping it finely tuned to provide the most accurate predictions.",
    icon: FaRecycle,
    color: "yellow.400",
  },
  {
    id: "about-fact-6",
    title: "Specialized Data for Niche Auctions",
    text: "Unlike standard car auctions, BAT auctions are unique. We exclusively employ data from specialized 'niche' car auctions to train our models, ensuring relevance.",
    icon: FaCar,
    color: "red.400",
  },
];

export default function About() {
  return (
    <>
      <Box>
        {/* Setting the title and meta description */}
        <HelmetProvider>
          <Helmet>
            <title>About PredictATrailer</title>
            <meta
              name="description"
              content="Discover the story behind PredictATrailer. Learn about our mission to revolutionize the automotive auction experience and provide you with accurate vehicle price predictions."
            />
          </Helmet>
        </HelmetProvider>
      </Box>
      <Flex
        align="center"
        justify="center"
        minHeight={{ base: "96vh", md: "90vh", lg: "88vh" }}
        bg="backgroundColor"
        id="about"
      >
        <Box p={2} mt={6} minHeight={{ base: "96vh", md: "90vh", lg: "88vh" }}>
          <Stack spacing={4} as={Container} maxW={"4xl"} textAlign={"center"}>
            <Heading fontSize={"5xl"}>About Us</Heading>
            <Text color={"gray.400"} fontSize={"lg"} textAlign={"start"}>
              {
                "As avid car enthusiasts, we've spent countless hours scouring BringATrailer for those hidden gems. But the process of researching and determining a fair price for a specific vehicle can be an arduous task. That's when inspiration struck – what if we could develop a cutting-edge model that could swiftly and accurately predict the ideal selling price for any vehicle? Imagine the time saved, and the edge we'd gain as buyers in the know. "
              }
              <br />
              <br />
              {
                "Driven by this vision, we embarked on the journey to create PredictATrailer and make it accessible to fellow enthusiasts worldwide. Our mission is simple: to revolutionize the way we buy and sell vehicles, arming every car lover with the power of informed decisions. Welcome to PredictATrailer – where automotive dreams become reality!"
              }
            </Text>
          </Stack>

          <Container maxW={"4xl"} mt={6} mb={12}>
            <Heading pb={4} mb={5} fontSize={"4xl"} textAlign={"center"}>
              How Does It Work?
            </Heading>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} spacing={10}>
              {features.map((feature) => (
                <HStack p={2} key={feature.id} align={"top"}>
                  <Box color={feature.color} px={2}>
                    <Icon as={feature.icon} boxSize={8} />
                  </Box>
                  <VStack align={"start"}>
                    <Text fontWeight={600} fontSize={"lg"}>
                      {feature.title}
                    </Text>
                    <Text color={"gray.400"}>{feature.text}</Text>
                  </VStack>
                </HStack>
              ))}
            </SimpleGrid>
          </Container>
        </Box>
      </Flex>
    </>
  );
}
