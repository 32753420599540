import {
  Box,
  Container,
  Divider,
  Heading,
  Image,
  Stack,
  useBreakpointValue,
} from "@chakra-ui/react";
import UserFilterVehicleData from "../Components/UserFilterVehicleData";
import { useState } from "react";
import { RoundedStatisticsBlock } from "../Components/Common/RoundedStatisticsBlock";
import { IconSquare } from "../Components/Common/IconSquare";
import { ThreeColumnStatisticBlock } from "../Components/Common/ThreeColumnStatisticBlock";
import ChartWrapper from "../Components/Charts/ChartWrapper";
import AveragePriceVsYearAreaChart from "../Components/Charts/AveragePriceVsYearAreaChart";
import AverageMileageVsYearAreaChart from "../Components/Charts/AverageMileageVsYearAreaChart";
import PriceVsMileageScatterPlot from "../Components/Charts/PriceVsMileageScatterPlot";
import SoldByColorBarChart from "../Components/Charts/SoldByColorBarChart";
import SalesByYearBarChart from "../Components/Charts/SalesByYearBarChart";
import { SoldVehicleInfoContainer } from "../Components/SoldVehicleInfo/SoldVehicleInfoContainer";
import theme from "../Components/Theme/theme";
import { Helmet, HelmetProvider } from "react-helmet-async";
import {
  FaCaretUp,
  FaCaretDown,
  FaSortAmountUp,
  FaSortAmountDown,
  FaRoad,
  FaWallet,
  FaCar,
  FaThumbsUp,
  FaThumbsDown,
} from "react-icons/fa";

export default function Analytics() {
  // Vehicle data used for stats & charts
  const scatterPlotColor = "#D3A346";
  const [finalFilteredData, setFinalFilteredData] = useState([]);
  const [modelData, setModelData] = useState({
    averagePrice: 0,
    deltaPrice: 0,
    averageMileage: 0,
    deltaMileage: 0,
    lowMileagePriceAverage: 0,
    highMileagePriceAverage: 0,
    startYear: 0,
    endYear: 0,
    priceTrend: 0,
    mileageTrend: 0,
    bestAndWorstModelYearDict: {},
    bestAndWorstColorDict: {},
    vehicleImageUrl: "",
    make: "",
    model: "",
  });

  const SMALL_ICON_SIZE = useBreakpointValue(theme.icons.smallSizeIcon);
  const MEDIUM_ICON_SIZE = useBreakpointValue(theme.icons.mediumSizeIcon);
  const LARGE_ICON_SIZE = useBreakpointValue(theme.icons.largeSizeIcon);

  // Text to use for price trend tooltip
  const priceTrendText = {
    increasing: "Prices for this vehicle have been increasing the last year",
    decreasing:
      "Prices for this vehicle have been decreasing over the last year",
    neutral:
      "Prices for this vehicle have remained stable over the last year or there is not enough data to estimate",
  };

  // Text to use for mileage trend tooltip
  const mileageTrendText = {
    increasing:
      "Mileage for this vehicle has been increasing over the last year",
    decreasing:
      "Mileage for this vehicle has been decreasing over the last year",
    neutral:
      "Mileage for this vehicle has remained stable over the last year or there is not enough data to estimate",
  };

  return (
    <>
      <Box>
        {/* Set the title and meta description */}
        <HelmetProvider>
          <Helmet>
            <title>BaT Analytics</title>
            <meta
              name="description"
              content="Uncover in-depth insights into Bringatrailer vehicle data. Analyze trends, historical pricing, model popularity, and more with our robust analytics tools. Elevate your understanding of the market with comprehensive visualizations and statistics."
            />
          </Helmet>
        </HelmetProvider>
      </Box>
      <Box
        maxW="100vw"
        overflow="hidden"
        bg="backgroundColor"
        textAlign="center"
        fontSize="xl"
        minHeight={{ base: "96vh", md: "90vh", lg: "88vh" }}
        position={"relative"}
        pb={8}
      >
        <Container
          mt={8}
          maxW={{
            base: "100%",
            sm: "100%",
            md: "95%",
            lg: "95%",
            xl: "95%",
            "2xl": "88%",
          }}
        >
          <Stack
            direction={{ base: "column", lg: "row" }}
            spacing={{ base: 6, md: 8 }}
            justifyContent="center"
            alignItems="stretch"
            margin="auto"
            mb={8}
            w="100%"
          >
            <Box w={{ base: "100%", lg: "25%" }} bg="backgroundColor">
              <UserFilterVehicleData
                finalFilteredData={finalFilteredData}
                setFinalFilteredData={setFinalFilteredData}
                setModelData={setModelData}
              />
            </Box>
            {finalFilteredData.length > 0 && (
              <>
                <Stack
                  w={{ base: "100%", lg: "65%" }}
                  direction={{ base: "column", lg: "row" }}
                  spacing={{ base: 6, md: 8 }}
                >
                  <Box
                    bgPosition="center"
                    backgroundSize="cover"
                    w={{ base: "100%", lg: "57%", "2xl": "50%" }}
                    borderRadius={12}
                    position="relative"
                    overflow="hidden"
                    h={{ base: "30vh", lg: "100%" }}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Image
                      src={modelData.vehicleImageUrl}
                      borderRadius={12}
                      position="absolute"
                      w="100%"
                      h="100%"
                      objectFit="cover"
                      alt="vehicle image"
                    />
                  </Box>
                  <Box
                    w={{ base: "100%", lg: "43%", "2xl": "50%" }}
                    bg="secondaryBackgroundColor"
                    borderRadius={12}
                    borderWidth={1}
                    borderColor="borderColor"
                    py={2}
                    px={4}
                    justify="center"
                    align="center"
                  >
                    <Heading
                      px={2}
                      pt={1}
                      size={{ base: "sm", md: "md", "3xl": "md" }}
                      mb={2}
                      bgGradient="linear(to-r, yellow.400, yellow.400, red.400, red.400)"
                      bgClip="text"
                    >
                      {`${modelData.make.toUpperCase()} ${modelData.model.toUpperCase()}'s`}
                    </Heading>
                    <Divider
                      w={{ base: "100%", lg: "95%" }}
                      mt={2}
                      borderWidth={1}
                      borderColor="borderColor"
                    />
                    <Divider
                      w={{ base: "100%", lg: "95%" }}
                      mt={2}
                      borderWidth={1}
                      borderColor="borderColor"
                    />
                    {/* Average Price */}
                    <Stack
                      w="100%"
                      py={4}
                      px={{ base: 0, lg: 4 }}
                      spacing={4}
                      justifyContent="space-evenly"
                    >
                      <ThreeColumnStatisticBlock
                        icon={
                          <FaWallet
                            color="#38A169"
                            fontSize={MEDIUM_ICON_SIZE}
                          />
                        }
                        textColor={"#38A169"}
                        iconBackgroundColor={"gray.50"}
                        heading="AVG. PRICE"
                        tooltipLabel="Average price of all vehicles sold"
                      >
                        {"$" + modelData.averagePrice.toLocaleString()}
                      </ThreeColumnStatisticBlock>
                      {/* Average Mileage   */}
                      <ThreeColumnStatisticBlock
                        icon={
                          <FaRoad color="#E53E3E" fontSize={MEDIUM_ICON_SIZE} />
                        }
                        textColor={"#E53E3E"}
                        iconBackgroundColor={"gray.50"}
                        heading="AVG. MILEAGE"
                        tooltipLabel="Average mileage of all vehicles sold"
                      >
                        {modelData.averageMileage.toLocaleString()}
                      </ThreeColumnStatisticBlock>
                      {/* Number of vehicles sold */}
                      <ThreeColumnStatisticBlock
                        icon={
                          <FaCar
                            color={scatterPlotColor}
                            fontSize={MEDIUM_ICON_SIZE}
                          />
                        }
                        textColor={scatterPlotColor}
                        iconBackgroundColor={"gray.50"}
                        heading="VEHICLES SOLD"
                        tooltipLabel="Number of vehicles that sold"
                      >
                        {
                          finalFilteredData.map((item) => item.sold === "True")
                            .length
                        }
                      </ThreeColumnStatisticBlock>
                    </Stack>
                  </Box>
                </Stack>
              </>
            )}
          </Stack>
          {/* START CHARTS AND STATISTICS */}
          {finalFilteredData.length > 0 && (
            <>
              <Stack
                direction={{ base: "column", lg: "row" }}
                spacing={{ base: 6, md: 8 }}
                justifyContent="center"
                alignItems="stretch"
                margin="auto"
                mb={8}
                w="100%"
              >
                {/* START PRICE TRENDS */}
                <Box w={{ base: "100%", lg: "25%" }}>
                  <RoundedStatisticsBlock
                    heading="Prices"
                    titleTopText="PRICE TREND"
                    titleBottomText={"AT A RATE OF"}
                    topToolTipLabel={
                      modelData.deltaPrice === 0
                        ? priceTrendText.neutral
                        : modelData.deltaPrice > 0
                        ? priceTrendText.increasing
                        : priceTrendText.decreasing
                    }
                    bottomToolTipLabel="Rate at which the price has been changing year over year (YoY)"
                    topText={
                      modelData.deltaPrice === 0
                        ? "UNKNOWN"
                        : modelData.deltaPrice > 0
                        ? "INCREASING"
                        : "DECREASING"
                    }
                    bottomText={
                      (modelData.deltaPrice >= 0 ? "+" : "-") +
                      "$" +
                      Math.abs(modelData.deltaPrice).toLocaleString() +
                      " YoY"
                    }
                    textColor={
                      modelData.deltaPrice > 0 ? "redChartTheme" : "green.500"
                    }
                    iconSquareTop={
                      <IconSquare
                        bgColor={
                          modelData.deltaPrice > 0
                            ? "redChartTheme"
                            : "green.500"
                        }
                        icon={
                          modelData.deltaPrice > 0 ? (
                            <FaSortAmountUp
                              color="secondaryBackgroundColor"
                              fontSize={MEDIUM_ICON_SIZE}
                            />
                          ) : (
                            <FaSortAmountDown
                              color="secondaryBackgroundColor"
                              fontSize={MEDIUM_ICON_SIZE}
                            />
                          )
                        }
                      />
                    }
                    iconSquareBottom={
                      <IconSquare
                        bgColor={
                          modelData.deltaPrice > 0
                            ? "redChartTheme"
                            : "green.500"
                        }
                        icon={
                          modelData.deltaPrice > 0 ? (
                            <FaCaretUp
                              color="secondaryBackgroundColor"
                              fontSize={LARGE_ICON_SIZE}
                            />
                          ) : (
                            <FaCaretDown
                              color="secondaryBackgroundColor"
                              fontSize={LARGE_ICON_SIZE}
                            />
                          )
                        }
                      />
                    }
                  />
                </Box>
                <Box w={{ base: "100%", lg: "65%" }}>
                  <ChartWrapper
                    heading="Average Price vs. Auction Year"
                    chart={AveragePriceVsYearAreaChart}
                    data={finalFilteredData}
                    color="#D34C46"
                    labelColor="white"
                    height={250}
                  />
                </Box>
              </Stack>
              {/* START MILEAGE TRENDS */}
              <Stack
                direction={{ base: "column", lg: "row" }}
                spacing={{ base: 6, md: 8 }}
                justifyContent="center"
                alignItems="stretch"
                margin="auto"
                mb={8}
                w="100%"
              >
                <Box w={{ base: "100%", lg: "25%" }}>
                  <RoundedStatisticsBlock
                    heading="Miles"
                    titleTopText="MILEAGE TREND"
                    titleBottomText={"AT A RATE OF"}
                    topToolTipLabel={
                      modelData.deltaMileage === 0
                        ? mileageTrendText.neutral
                        : modelData.deltaMileage > 0
                        ? mileageTrendText.increasing
                        : mileageTrendText.decreasing
                    }
                    bottomToolTipLabel="Rate at which mileage has been changing year over year (YoY)"
                    topText={
                      modelData.deltaMileage === 0
                        ? "UNKNOWN"
                        : modelData.deltaMileage > 0
                        ? "INCREASING"
                        : "DECREASING"
                    }
                    bottomText={
                      (modelData.deltaMileage >= 0 ? "+" : "-") +
                      Math.abs(modelData.deltaMileage).toLocaleString() +
                      " YoY"
                    }
                    textColor={
                      modelData.deltaMileage > 0 ? "redChartTheme" : "green.500"
                    }
                    iconSquareTop={
                      <IconSquare
                        bgColor={
                          modelData.deltaMileage > 0
                            ? "redChartTheme"
                            : "green.500"
                        }
                        icon={
                          modelData.deltaMileage > 0 ? (
                            <FaSortAmountUp
                              color="secondaryBackgroundColor"
                              fontSize={MEDIUM_ICON_SIZE}
                            />
                          ) : (
                            <FaSortAmountDown
                              color="secondaryBackgroundColor"
                              fontSize={MEDIUM_ICON_SIZE}
                            />
                          )
                        }
                      />
                    }
                    iconSquareBottom={
                      <IconSquare
                        bgColor={
                          modelData.deltaMileage > 0
                            ? "redChartTheme"
                            : "green.500"
                        }
                        icon={
                          modelData.deltaMileage > 0 ? (
                            <FaCaretUp
                              color="secondaryBackgroundColor"
                              fontSize={LARGE_ICON_SIZE}
                            />
                          ) : (
                            <FaCaretDown
                              color="secondaryBackgroundColor"
                              fontSize={LARGE_ICON_SIZE}
                            />
                          )
                        }
                      />
                    }
                  />
                </Box>
                <Box w={{ base: "100%", lg: "65%" }}>
                  <ChartWrapper
                    heading="Average Mileage vs. Auction Year"
                    chart={AverageMileageVsYearAreaChart}
                    data={finalFilteredData}
                    color="#D34C46"
                    labelColor="white"
                    height={250}
                  />
                </Box>
              </Stack>
              {/* START LOW VS HIGH MILEAGE TRENDS */}
              <Stack
                direction={{ base: "column", lg: "row" }}
                spacing={{ base: 6, md: 8 }}
                justifyContent="center"
                alignItems="stretch"
                margin="auto"
                mb={8}
                w="100%"
              >
                <Box w={{ base: "100%", lg: "25%" }}>
                  <RoundedStatisticsBlock
                    heading="Prices & Miles"
                    titleTopText="LOWER MILES"
                    titleBottomText={"HIGHER MILES"}
                    topToolTipLabel="Average price of vehicles with lower than average mileage"
                    bottomToolTipLabel="Average price of vehicles with greater than average mileage"
                    topText={
                      "$" + modelData.lowMileagePriceAverage.toLocaleString()
                    }
                    bottomText={
                      "$" + modelData.highMileagePriceAverage.toLocaleString()
                    }
                    textColor={"green.500"}
                    bottomTextColor={"red.500"}
                    iconSquareTop={
                      <IconSquare
                        bgColor="green.500"
                        icon={
                          <FaCaretDown
                            color="secondaryBackgroundColor"
                            fontSize={LARGE_ICON_SIZE}
                          />
                        }
                      />
                    }
                    iconSquareBottom={
                      <IconSquare
                        bgColor="redChartTheme"
                        icon={
                          <FaCaretUp
                            color="secondaryBackgroundColor"
                            fontSize={LARGE_ICON_SIZE}
                          />
                        }
                      />
                    }
                  />
                </Box>
                <Box w={{ base: "100%", lg: "65%" }}>
                  <ChartWrapper
                    heading="Vehicle Price vs. Mileage"
                    chart={PriceVsMileageScatterPlot}
                    data={finalFilteredData}
                    color="#D34C46"
                    labelColor="white"
                    height={250}
                  />
                </Box>
              </Stack>
              {/* START PRICE VS COLORS */}
              <Stack
                direction={{ base: "column", lg: "row" }}
                spacing={{ base: 6, md: 8 }}
                justifyContent="center"
                alignItems="stretch"
                margin="auto"
                mb={8}
                w="100%"
              >
                <Box w={{ base: "100%", lg: "25%" }}>
                  <RoundedStatisticsBlock
                    heading="Colors"
                    titleTopText="BEST COLOR"
                    titleBottomText="WORST COLOR"
                    topToolTipLabel="The highest grossing color based on average price"
                    bottomToolTipLabel="The lowest grossing color based on average price"
                    topText={`${modelData.bestAndWorstColorDict.high.color.toUpperCase()}: $${modelData.bestAndWorstColorDict.high.price.toLocaleString()}`}
                    bottomText={`${modelData.bestAndWorstColorDict.low.color.toUpperCase()}: $${modelData.bestAndWorstColorDict.low.price.toLocaleString()}`}
                    textColor={"green.500"}
                    bottomTextColor={"red.500"}
                    iconSquareTop={
                      <IconSquare
                        bgColor="green.500"
                        icon={
                          <FaThumbsUp
                            color="secondaryBackgroundColor"
                            fontSize={SMALL_ICON_SIZE}
                          />
                        }
                      />
                    }
                    iconSquareBottom={
                      <IconSquare
                        bgColor="redChartTheme"
                        icon={
                          <FaThumbsDown
                            color="secondaryBackgroundColor"
                            fontSize={SMALL_ICON_SIZE}
                          />
                        }
                      />
                    }
                  />
                </Box>
                <Box w={{ base: "100%", lg: "65%" }}>
                  <ChartWrapper
                    heading="Average Price vs. Color"
                    chart={SoldByColorBarChart}
                    data={finalFilteredData}
                    color="#D34C46"
                    labelColor="white"
                    height={250}
                  />
                </Box>
              </Stack>
              {/* START PRICE VS MODEL YEARS */}
              <Stack
                direction={{ base: "column", lg: "row" }}
                spacing={{ base: 6, md: 8 }}
                justifyContent="center"
                alignItems="stretch"
                margin="auto"
                mb={4}
                w="100%"
              >
                <Box w={{ base: "100%", lg: "25%" }}>
                  <RoundedStatisticsBlock
                    heading="Model Years"
                    titleTopText="BEST YEAR"
                    titleBottomText="WORST YEAR"
                    topToolTipLabel="The highest grossing year based on average price"
                    bottomToolTipLabel="The lowest grossing year based on average price"
                    topText={`${
                      modelData.bestAndWorstModelYearDict.high.year
                    }: $${modelData.bestAndWorstModelYearDict.high.price.toLocaleString()}`}
                    bottomText={`${
                      modelData.bestAndWorstModelYearDict.low.year
                    }: $${modelData.bestAndWorstModelYearDict.low.price.toLocaleString()}`}
                    textColor={"green.500"}
                    bottomTextColor={"red.500"}
                    iconSquareTop={
                      <IconSquare
                        bgColor="green.500"
                        icon={
                          <FaThumbsUp
                            color="secondaryBackgroundColor"
                            fontSize={SMALL_ICON_SIZE}
                          />
                        }
                      />
                    }
                    iconSquareBottom={
                      <IconSquare
                        bgColor="redChartTheme"
                        icon={
                          <FaThumbsDown
                            color="secondaryBackgroundColor"
                            fontSize={SMALL_ICON_SIZE}
                          />
                        }
                      />
                    }
                  />
                </Box>
                <Box w={{ base: "100%", lg: "65%" }}>
                  <ChartWrapper
                    heading="Average Price vs. Model Year"
                    chart={SalesByYearBarChart}
                    data={finalFilteredData}
                    color="#D34C46"
                    labelColor="white"
                    height={250}
                  />
                </Box>
              </Stack>
              {/* START SIMILAR VEHICLES THAT HAVE SOLD */}
              <Box
                margin="auto"
                justifyContent="center"
                alignContent="center"
                w={{ base: "100%", md: "93%" }}
                pr={{ base: 0, md: 2 }}
              >
                <SoldVehicleInfoContainer
                  heading="SOLD VEHICLES"
                  vehicleData={finalFilteredData}
                />
              </Box>
            </>
          )}
        </Container>
      </Box>
    </>
  );
}
