// Format vehicle mileage with comma
export const formatNumberWithCommas = (value) => {
  if (!value) {
    return ""; // Or you can choose to throw an error or handle it differently
  }
  try {
    const stringifiedValue = value.toString();
    const parts = stringifiedValue.replace(/\D/g, "").split("");
    let formattedValue = "";
    while (parts.length > 3) {
      formattedValue = "," + parts.splice(-3).join("") + formattedValue;
    }
    formattedValue = parts.join("") + formattedValue;
    return formattedValue;
  } catch (error) {
    return "";
  }
};
