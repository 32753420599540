import { getVehicleInfoFromApi } from "./getVehicleInfoFromApi";
import getSingleVehicleDataFromDatabase from "./getSingleVehicleDataFromDatabase";

/**
 * Retrieves model data and predictions.
 *
 * @param {string} url - The URL to retrieve the data from.
 * @param {function} updateBadUrl - A function to update the state when the URL is invalid or returns an error.
 * @param {function} updateLoading - A function to update the state while the data is being fetched.
 * @param {function} updatePrediction - A function to update the state with the prediction result.
 * @param {function} updateMileage - A function to update the state with the mileage data.
 * @param {string} image - Ref to the image data related to the model.
 * @param {string} model - Ref to the model data.
 * @param {boolean} live - Ref that indicates whether the data is live or not.
 * @param {number} price - Ref to the price data.
 * @param {number} avgPrice - Ref to the average price data.
 * @param {number} avgMileage - Ref to the average mileage data.
 * @param {function} setFilteredData - A function to update the filtered data state based on the prediction.
 * @returns {Promise<void>} - A Promise that resolves once the data is fetched and processed.
 */
export const getPrediction = async ({
  url,
  setUrl,
  updateBadUrl,
  setIsLoading,
  setVehicleData,
  setIsVehicleLive,
}) => {
  let response = [];
  try {
    response = await getSingleVehicleDataFromDatabase({
      url: url,
      setVehicleData: setVehicleData,
    });
  } catch (error) {
    console.log("Error fetching data, please refresh.", error);
  }

  if (response.length === 0) {
    getVehicleInfoFromApi({
      url: url,
      setUrl: setUrl,
      updateBadUrl: updateBadUrl,
      setIsLoading: setIsLoading,
      setVehicleData: setVehicleData,
      setIsVehicleLive: setIsVehicleLive,
    });
  } else {
    setIsVehicleLive(false);
  }
};
