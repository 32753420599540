/**
 * Calculates the average price and mileage of vehicles based on the provided criteria.
 * Updates the given refs with the calculated values.
 *
 * @param {array} data - Array of vehicle data.
 * @param {array|number} vehicleYear - Year or year range to filter vehicles.
 */
export function calculateAveragePriceAndMileage(data, vehicleYear) {
  let priceSum = 0;
  let mileageSum = 0;
  let count = 0;

  if (vehicleYear) {
    if (Array.isArray(vehicleYear)) {
      // Calculate sum and count for vehicles within the year range
      for (let i = 0; i < data.length; i++) {
        const year = data[i].year;
        if (year >= vehicleYear[0] && year <= vehicleYear[1]) {
          count += 1;
          priceSum += data[i].bid_price;
          mileageSum += data[i].miles;
        }
      }
    } else if (!isNaN(vehicleYear)) {
      const vehicleYearInt = parseInt(vehicleYear);
      // Calculate sum and count for vehicles of the specific year
      for (let i = 0; i < data.length; i++) {
        if (data[i].year === vehicleYearInt) {
          count += 1;
          priceSum += data[i].bid_price;
          mileageSum += data[i].miles;
        }
      }
    }
  }

  let averagePrice = count > 0 ? Math.floor(priceSum / count) : 0;
  let averageMileage = count > 0 ? Math.floor(mileageSum / count) : 0;

  return [averagePrice, averageMileage];
}
