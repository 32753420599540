export function capitalizeLetters(value) {
  try {
    const stringifiedValue = value.toString();

    let words = stringifiedValue.split(" ");

    const allCaps = words.map((word) => {
      const letters = Array.from(word).map((letter) => {
        if (/^[a-zA-Z]+$/.test(letter)) {
          return letter.toUpperCase();
        }
        return letter;
      });

      return letters.join("");
    });

    return allCaps.join(" ");
  } catch (error) {
    return "";
  }
}
