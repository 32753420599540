import axios from "axios";

export const getVehicleInfoFromApi = async ({
  url,
  setUrl,
  updateBadUrl,
  setIsLoading,
  setVehicleData,
  setIsVehicleLive,
}) => {
  function isValidURL(url) {
    const pattern = /^(https?:\/\/)?([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,6})(\/\S*)?$/;
    return pattern.test(url);
  }

  try {
    const api_key = process.env.REACT_APP_API_KEY;

    // Check for correct URLs
    if (
      url === "" ||
      !url.includes("https://bringatrailer.com/") ||
      !isValidURL(url)
    ) {
      updateBadUrl(true);
      setIsLoading(false);
      return;
    }

    // Update loading screen
    setIsLoading(true);
    updateBadUrl(false);

    // Query ML model
    const result = await axios.post(
      "https://tropski.pythonanywhere.com/prediction",
      {
        url: url,
        api_key: api_key,
      }
    );

    // Get vehicle data from .data key
    const vehicleData = result.data;

    // Update vehicle params from queried data
    const year = parseInt(vehicleData["model-info"][0]);
    const make = vehicleData["model-info"][1];
    const model = vehicleData["model-info"][2].replace(/make/gi, ""); // remove "make" if in model name - error from scraping tool
    let price = vehicleData.price === 1 ? 0 : vehicleData.price; // if price === 1 it means the auction has just started and has a value of 0
    const prediction =
      vehicleData.prediction > 0 ? vehicleData.prediction : 100;
    const miles = vehicleData.miles;
    const live = vehicleData.live;
    const image = vehicleData.image;

    // Check for bad URL entry from ML model, i.e., 1973BADURL or 2022BADURL
    if (
      [year, make, model].join("") === "1973BADURL" ||
      (make === "BAD" && model === "URL")
    ) {
      setUrl("");
      updateBadUrl(true);
      setIsLoading(false);
      return;
    }

    setVehicleData((prevVehicleData) => ({
      ...prevVehicleData,
      year: year,
      make: make,
      model: model,
      mileage: miles,
      bid_price: price,
      prediction: prediction,
      image: image,
    }));
    setIsVehicleLive(live ? true : false);

    // // Dynamically update title name
    document.title = `predictatrailer: ${[year, make, model].join(" ")}`;

    // Dynamically update image meta tags
    const ogImageMeta = document.querySelector('meta[property="og:image"]');
    const itemPropImage = document.querySelector('meta[itemprop="image"]');
    ogImageMeta.content = itemPropImage.content = image.split("?")[0];
    setIsLoading(false);
  } catch (error) {
    console.error("Could not reach URL.", error);
    setUrl("");
    updateBadUrl(true);
    setIsLoading(false);
    return;
  }
};
