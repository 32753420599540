import {
  Box,
  Button,
  Divider,
  Heading,
  Image,
  Link,
  Stack,
} from "@chakra-ui/react";
import { capitalizeLetters } from "../Utils/capitalizeLetters";
import { formatNumberWithCommas } from "../Utils/formatNumberWithCommas";

export const SoldVehicleInfoCard = ({ vehicleData }) => {
  const year = vehicleData.year.toString();
  const make = capitalizeLetters(vehicleData.make);
  const model = capitalizeLetters(vehicleData.model);
  const yearMakeModel = `${year} ${make} ${model}`;
  const image = vehicleData.image;
  const urlQueryString = "/?url=" + encodeURIComponent(vehicleData.url);

  return (
    <Box
      bg="secondaryBackgroundColor"
      borderRadius={12}
      borderWidth={1}
      borderColor="borderColor"
    >
      <Stack
        textAlign="center"
        direction={{ base: "column", md: "column" }}
        spacing={2}
      >
        <Link href={urlQueryString}>
          <Image
            borderRadius={11}
            borderBottomRadius={0}
            href={vehicleData.url}
            _hover={{
              cursor: "pointer",
            }}
            w="100%"
            alt={yearMakeModel + "Auction Picture"}
            src={image}
          />
        </Link>
        <Stack pt={2} pb={4} spacing={2}>
          <Heading
            pb={2}
            mx={2}
            textAlign="center"
            size={{ base: "sm", md: "md" }}
            bgGradient="linear(to-r, yellow.400, yellow.400, red.400, red.400)"
            bgClip="text"
          >
            {yearMakeModel}
          </Heading>
          <Stack direction="row" justifyContent="space-evenly">
            <Box
              w="40%"
              borderWidth={1}
              borderColor="borderColor"
              borderRadius={12}
              py={2}
            >
              <Stack w="100%" align="center">
                <Heading size={{ base: "md", md: "sm" }}>Mileage</Heading>
                <Divider borderColor="borderColor" w="70%" />
                <Heading size={{ base: "md", md: "md" }}>
                  {formatNumberWithCommas(vehicleData.miles)}
                </Heading>
              </Stack>
            </Box>
            <Box
              w="40%"
              borderWidth={1}
              borderColor="borderColor"
              borderRadius={12}
              py={2}
            >
              <Stack w="100%" align="center">
                <Heading size={{ base: "md", md: "sm" }}>Sale Price</Heading>
                <Divider borderColor="borderColor" w="70%" />
                <Heading size={{ base: "md", md: "md" }}>
                  {"$" + formatNumberWithCommas(vehicleData.bid_price)}
                </Heading>
              </Stack>
            </Box>
          </Stack>
          <Box pt={3} justifyContent="center">
            <Link href={urlQueryString}>
              <Button
                w={{ base: "70%", md: "40%" }}
                bg="redChartTheme"
                color="white"
                colorScheme="red"
              >
                Analyze
              </Button>
            </Link>
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
};
