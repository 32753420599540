import { Box } from "@chakra-ui/react";

// Box with an icon indicator
export const IconCircle = ({ bgColor, icon }) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      w={{ base: "35px", md: "40px", xl: "50px" }}
      h={{ base: "35px", md: "40px", xl: "50px" }}
      bg={bgColor}
      borderRadius={"50%"}
    >
      {icon}
    </Box>
  );
};
