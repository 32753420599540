import { Box, Divider, Flex, Heading, Stack, Text, Link } from "@chakra-ui/react";


export default function Privacy() {

  return (
    <Flex
        bg="backgroundColor"
        py={{base: 4, md: 16}}
        align="start"
        justify="center"
        minHeight={{ base:"96vh", md: "90vh", lg: "88vh"}}
        id="privacy">
      <Box borderRadius={12} borderWidth={1} borderColor="borderColor" p={8} bg="secondaryBackgroundColor" maxW={{base: "90vw", md: "80vw", xl: "75vw"}}>
        <Stack spacing={8}>
          <Heading maxW={{base: "100%", md:"50%"}} size="xl" bgGradient='linear(to-r, green.400, blue.400, purple.400, red.400, red.400)' bgClip='text'>
            Privacy Policy
          </Heading>
          <Text fontSize="lg">
          At predictatrailer, we take your privacy and data security very seriously. 
          This privacy policy statement explains how we collect, use, and protect any 
          personal information that you provide to us when you use our website.
          </Text>
          <Stack>
            <Heading size="md">
              Information we collect
            </Heading>
            <Text fontSize="lg">
            In addition to your email address and name, which are necessary for authentication
             purposes through Auth0, we also collect data related to your use of our machine learning (ML) prediction
              service. This includes information about the models you use, the inputs you provide, and
               the outputs you receive. We also use cookies to monitor the number of times you use our
                ML prediction service. These cookies do not collect any personal information about you.
            </Text>
          </Stack>
          <Stack>
            <Heading size="md">
              How we use your information
            </Heading>
            <Text fontSize="lg">
            We use the data we collect about your use of our ML prediction service to improve and refine our models,
             ensuring that we are providing the most accurate predictions possible. We also use this data to monitor and
              analyze the performance of our service. The information we collect through cookies is used solely for monitoring
               the number of times you use our service, and is not shared with any third parties.
              The login information we collect is solely used for authentication purposes through our Auth0 service.
              We do not share, sell, or rent your personal information to any third-party, nor do we use it for
                any other purpose other than for the purpose of verifying your identity and providing you access
                to our website.
            </Text>
          </Stack>
          <Stack>
            <Heading size="md">
              Data security
            </Heading>
            <Text fontSize="lg">
              We take all necessary measures to protect your personal information from unauthorized access, alteration,
               disclosure, or destruction. We use industry-standard security technologies and procedures to safeguard
                your personal information, and we regularly review and update our security practices to ensure that we
                 maintain the highest level of data protection.
            </Text>
          </Stack>
          <Stack>
            <Heading size="md">
              Do we use cookies?
            </Heading>
            <Text fontSize="lg">
              Yes, we use cookies to monitor the number of times you use our ML prediction service. These cookies do not
              collect any personal information about you and are used solely for monitoring purposes.
            </Text>
          </Stack>
          <Stack>
            <Heading size="md">
              Changes to this policy
            </Heading>
            <Text fontSize="lg">
              We reserve the right to update or modify this privacy policy statement at any time. We will notify you of any changes by posting an updated policy on our website. Your continued use of our website after any changes or modifications to this policy will constitute your acceptance of the revised policy.
            </Text>
          </Stack>
          <Divider />
          <Text fontSize="lg">
            If you have any questions about this policy or our privacy practices, please {" "}
            <Link color="blue.500" to="/contact"> 
              contact us
            </Link>
            .
          </Text>
        </Stack>
      </Box>
    </Flex>
  )
}




