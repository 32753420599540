import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  Container,
  Divider,
  Heading,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Skeleton,
  Spinner,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { ThreeColumnStatisticBlockLargeText } from "../Components/Common/ThreeColumnStatisticBlockLargeText";
import {
  FaCar,
  FaRoad,
  FaWallet,
  FaRocket,
  FaGavel,
  FaArrowsAltH,
  FaRegChartBar,
  FaSearchDollar,
  FaChartLine,
  FaCrosshairs,
  FaArrowRight,
} from "react-icons/fa";
import { useEffect, useState } from "react";
import theme from "../Components/Theme/theme";
import AveragePriceVsYearAreaChart from "../Components/Charts/AveragePriceVsYearAreaChart";
import ChartWrapper from "../Components/Charts/ChartWrapper";
// import getSingleVehicleDataFromDatabase from "../Components/Utils/getSingleVehicleDataFromDatabase";
// import { useAuth0 } from "@auth0/auth0-react";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Link as ReactRouterLink } from "react-router-dom";
import getDataFromDatabase from "../Components/Utils/getDataFromDatabase";
import { SoldVehicleInfoContainer } from "../Components/SoldVehicleInfo/SoldVehicleInfoContainer";
import { getPrediction } from "../Components/Utils/getPrediction";
import { IconHeaderTextBox } from "../Components/Common/IconHeaderTextBox";
import { Helmet, HelmetProvider } from "react-helmet-async";
import getSingleVehicleDataFromJSON from "../Components/Utils/getSingleVehicleDataFromJSON";
import goodPredictions from "../Components/GoodPredictionsData.json";
import { CloseIcon } from "@chakra-ui/icons";

var initial_prediction =
  goodPredictions[Math.floor(Math.random() * goodPredictions.length)];

export default function Home() {
  const MEDIUM_ICON_SIZE = useBreakpointValue(theme.icons.mediumSizeIcon);
  const SCATTER_PLOT_COLOR = "#D3A346";
  const isSmallScreen = useBreakpointValue({ base: true, md: false });

  // initial configs
  // const { isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const urlQueryString = searchParams.get("url");
  const [url, setUrl] = useState(urlQueryString ? urlQueryString : "");
  const [isLoading, setIsLoading] = useState(true);
  const [initialPageLoaded, setInitialPageLoaded] = useState(false);
  const [isVehicleLive, setIsVehicleLive] = useState(false);
  const [badUrl, updateBadUrl] = useState(false);

  // initial vehicle configs
  const [vehicleData, setVehicleData] = useState({
    year: parseInt(new Date().getFullYear()),
    make: "",
    model: "",
    mileage: 0,
    bid_price: 0,
    prediction: 0,
    image: "",
  });
  const [averagePrice, setAveragePrice] = useState(0);
  const [averageMileage, setAverageMileage] = useState(0);
  const [similarModelData, setSimilarModelData] = useState([]);
  const [lowPriceEstimate, setLowPriceEstimate] = useState(0);
  const [highPriceEstimate, setHighPriceEstimate] = useState(0);
  const [isAnalyticsTextHovered, setIsAnalyticsTextHovered] = useState(false);

  // Formats price to $130k
  const priceRangeFormatter = (value) => {
    return "$" + parseInt((Math.round(value / 500) * 500) / 1000) + "k";
  };

  useEffect(() => {
    if (urlQueryString) {
      getPrediction({
        url: urlQueryString,
        setUrl: setUrl,
        updateBadUrl: updateBadUrl,
        setIsLoading: setIsLoading,
        setVehicleData: setVehicleData,
        setIsVehicleLive: setIsVehicleLive,
      });
    } else {
      // Get random vehicle data from initial prediction
      // Could only make one call to database - will need to check load times here to see if that makes sense
      updateBadUrl(false);

      // Get one vehicle's data on page load from good predictions list
      // Can also use getSingleVehicleDataFromDatabase but is a bit slower
      getSingleVehicleDataFromJSON({
        initialPrediction: initial_prediction,
        setVehicleData: setVehicleData,
      });
    }

    setInitialPageLoaded(true);
    // eslint-disable-next-line
  }, []);

  // Update similar vehicle data and high and low estimates when vehicleData changes
  useEffect(() => {
    setIsLoading(true);
    if (vehicleData.prediction > 0) {
      // get vehicle data from database
      getDataFromDatabase({
        vehicleData: vehicleData,
        setVehicleData: setVehicleData,
        setAveragePrice: setAveragePrice,
        setAverageMileage: setAverageMileage,
        setFilteredData: setSimilarModelData,
      });

      // Update high and low estimates when a vehicle data is updated
      const currentPricePrediction = vehicleData.prediction;
      const currentVehiclePrice = vehicleData.bid_price;

      const range = Math.abs(
        (currentPricePrediction - currentVehiclePrice) / currentPricePrediction
      );
      const goodMatchThreshold = 0.1;
      const badMatchThreshold = 0.25;

      const matchScalers = {
        great: { low: 0.91, high: 1.09 },
        good: { low: 0.84, high: 1.16 },
        bad: { low: 0.75, high: 1.25 },
      };

      let scaler;
      if (range < goodMatchThreshold) {
        scaler = matchScalers.great;
      } else if (range > badMatchThreshold) {
        scaler = matchScalers.bad;
      } else {
        scaler = matchScalers.good;
      }

      setLowPriceEstimate(
        priceRangeFormatter(currentPricePrediction * scaler.low)
      );
      setHighPriceEstimate(
        priceRangeFormatter(currentPricePrediction * scaler.high)
      );
    }
    setIsLoading(false);
  }, [vehicleData]);

  /**
   * Handles URL submission and prediction request.
   *
   * @param {Event} event - The event object representing the form submission.
   * @param {string} url - The URL to be used as input for prediction.
   *
   * This function is responsible for processing the user's input URL, creating any necessary URL parameters, and then sending a prediction request based on the provided URL. It is typically used as an event handler for a form submission.
   *
   * @returns {void} This function does not return a value. Instead, it triggers the prediction request and potentially updates the user interface based on the result.
   */
  function handlePredictionSubmit(event, url) {
    if (url) {
      // prevent reloading of page
      event.preventDefault();
      navigate({
        pathname: "/",
        search: "?" + createSearchParams({ url: url }),
      });
      // setUserDefinedPrediction(true);
      getPrediction({
        url: url,
        setUrl: setUrl,
        updateBadUrl: updateBadUrl,
        setIsLoading: setIsLoading,
        setVehicleData: setVehicleData,
        setIsVehicleLive: setIsVehicleLive,
      });
    } else {
      navigate({
        pathname: "/",
      });
      window.location.reload();
    }
  }

  /**
   * Handles handover nagivation to analytics page
   *
   * @param {string} make - The make of the current vehicle.
   * @param {string} model - The model of the current vehicle.
   *
   * This function is used to navigate to the Analytics page and pass the 'make' and 'model' parameters via the URL. It constructs a query string with the provided 'make' and 'model' values and appends it to the URL when navigating to the Analytics page.
   *
   * @returns {void} This function does not return a value. It triggers a navigation to the Analytics page with the specified parameters.
   */
  function handleNavigateToAnalytics(make, model) {
    navigate({
      pathname: "/analytics",
      search: "?" + createSearchParams({ make: make, model: model }),
    });
  }

  // Clears the URL without having to highlight and manually delete the URL input
  const handleClearInput = () => {
    setUrl("");
  };

  return (
    <>
      <Box>
        {/* Setting the title and meta description */}
        <HelmetProvider>
          <Helmet>
            <title>PredictATrailer</title>
            <meta
              name="description"
              content="Predict the final auction bid price of any bringatrailer auction on our platform. Explore historic data, make informed decisions, and get accurate predictions for your favorite vehicles."
            />
          </Helmet>
        </HelmetProvider>
      </Box>
      <Box
        maxW="100vw"
        overflow="hidden"
        bg="backgroundColor"
        textAlign="center"
        fontSize="xl"
        minHeight={{ base: "96vh", md: "90vh", lg: "88vh" }}
        position="relative"
      >
        <Container
          maxW={{
            base: "100%",
            sm: "100%",
            md: "95%",
            lg: "85%",
            xl: "80%",
            "2xl": "70%",
          }}
        >
          <Box>
            <Center>
              <Heading
                pb="0.5rem"
                // mt={"16px"}
                mt={{ base: "8px", md: "48px" }}
                mb={"15px"}
                size={["xl", "xl", "xl", "2xl"]}
                fontSize="50px"
              >
                Bring-A-Trailer Auction Predictor
              </Heading>
            </Center>
            <Box
              w={{ base: "100%", md: "95%", "2xl": "65%" }}
              margin="auto"
              justifyContent="center"
              marginBottom={"15px"}
              textAlign="center"
              fontSize={["md", "md", "md"]}
              pb="0.25rem"
            >
              <Text>
                {isSmallScreen
                  ? "Enter a Bring-A-Trailer auction URL below to get your price prediction, in seconds!"
                  : "Unlock instant price predictions for any Bring-A-Trailer auction. Simply paste the auction URL and witness our cutting-edge machine learning and AI technology generate accurate predictions aligned with today's market dynamics, all within seconds!"}
              </Text>
              <Text fontSize="md" marginTop={"15px"}>
                Explore live Bring-A-Trailer auctions at{" "}
                <Link
                  color="blue.300"
                  href={"https://bringatrailer.com/auctions/"}
                  isExternal
                >
                  wwww.bringatrailer.com
                </Link>
              </Text>
            </Box>
            <Stack
              alignContent="center"
              justifyContent="center"
              direction={{ base: "column", md: "row" }}
              align="stretch"
              spacing="3%"
              margin="auto"
              marginBottom={"25px"}
              borderColor={"borderColor"}
              borderRadius={8}
              borderWidth={{ base: 0, md: 1 }}
              p={1.5}
              w={{ base: "100%", xl: "75%" }}
            >
              <InputGroup
                borderWidth={{ base: 1, md: 0 }}
                borderColor={"borderColor"}
                borderRadius={8}
              >
                {url && (
                  <InputLeftElement>
                    <Button
                      onClick={handleClearInput}
                      style={{ cursor: "pointer", background: "transparent" }}
                      _hover={{ color: "red.300" }}
                      color="redChartTheme"
                    >
                      {<CloseIcon />}
                    </Button>
                  </InputLeftElement>
                )}
                <Input
                  value={url}
                  placeholder="Enter a Bringatrailer Auction URL Here"
                  onChange={(e) => setUrl(e.target.value)}
                  width={["100%", "100%", "100%"]}
                  borderWidth={0}
                  _placeholder={{ color: "gray.300" }}
                  focusBorderColor="transparent"
                />
              </InputGroup>

              <Button
                width={["100%", "100%", "25%"]}
                onClick={(e) => handlePredictionSubmit(e, url)}
                bg="redChartTheme"
                color="white"
                colorScheme={"red"}
              >
                Get Prediction
              </Button>
            </Stack>
            <Link
              as={ReactRouterLink}
              to="/analytics"
              style={{ textDecoration: "none" }}
              onMouseEnter={() => setIsAnalyticsTextHovered(true)}
              onMouseLeave={() => setIsAnalyticsTextHovered(false)}
            >
              <Stack
                direction="row"
                justify="center"
                align="center"
                marginBottom={"50px"}
                spacing={isAnalyticsTextHovered ? 3 : 2}
                display="inline-flex"
              >
                <Text fontSize="md" color="white">
                  For historical vehicle data or analytics related to a certain
                  make & model, check out our
                  <Box as="span" color={{ base: "redChartTheme", md: "white" }}>
                    {" "}
                    analytics page
                  </Box>
                  <Box as="span" display={{ md: "none" }}>
                    !
                  </Box>
                </Text>
                <Box
                  display={{ base: "none", md: "flex" }}
                  justifyContent="center"
                  alignItems="center"
                  w={5}
                  h={5}
                  bg={"redChartTheme"}
                  borderRadius={{ base: "2", md: "50%" }}
                >
                  <Icon as={FaArrowRight} color="white" w="2.5" h="2.5" />
                </Box>
              </Stack>
            </Link>
          </Box>
          {badUrl ? (
            <Box w={{ base: "95vw", md: "90vw", lg: "68vw" }} mx="auto">
              <Stack>
                <Alert borderRadius={12} status="warning">
                  <AlertIcon />
                  That URL doesn't look quite right.. Please enter a URL that
                  looks like this:
                  "https://bringatrailer.com/listing/1986-porsche-944-turbo-242/"
                  and try again. If the vehicle was just posted, give it a few
                  minutes and try again.
                </Alert>
              </Stack>
            </Box>
          ) : vehicleData.prediction === 0 ||
            !initialPageLoaded ||
            isLoading ? (
            <>
              <Stack
                direction={{ base: "column", md: "row" }}
                startcolor="secondaryBackgroundColor"
                endcolor="backgroundColor"
              >
                <Stack w={{ base: "100%", md: "50%" }}>
                  <Skeleton height="30px" />
                  <Skeleton height="30px" />
                  <Skeleton height="30px" />
                </Stack>
                <Stack w={{ base: "100%", md: "50%" }}>
                  <Skeleton height="30px" />
                  <Skeleton height="30px" />
                  <Skeleton height="30px" />
                </Stack>
              </Stack>
            </>
          ) : (
            <>
              <Stack
                w="100%"
                direction={{ base: "column", md: "row" }}
                spacing={{ base: 6, md: 8 }}
              >
                <Box
                  bgPosition="center"
                  backgroundSize="cover"
                  w={{ base: "100%", md: "50%", "2xl": "50%" }}
                  borderRadius={12}
                  position="relative"
                  overflow="hidden"
                  h={{ base: "30vh", md: "auto" }}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Link
                    w="100%"
                    h="100%"
                    href={url.length > 0 ? url : initial_prediction.url}
                    isExternal
                  >
                    <Image
                      src={vehicleData.image}
                      borderRadius={12}
                      position="absolute"
                      w="100%"
                      h={{ base: "30vh", md: "100%" }}
                      objectFit="cover"
                      alt="vehicle image"
                    />
                  </Link>
                </Box>
                <Box
                  w={{ base: "100%", md: "50%", "2xl": "50%" }}
                  bg="secondaryBackgroundColor"
                  borderRadius={12}
                  borderWidth={1}
                  borderColor="borderColor"
                  py={2}
                  px={4}
                  justify="center"
                  align="center"
                >
                  <Heading
                    pr={0}
                    pl={5}
                    pt={1}
                    onClick={() =>
                      handleNavigateToAnalytics(
                        vehicleData.make?.toLowerCase(),
                        vehicleData.model?.toLowerCase()
                      )
                    }
                    size={{ base: "sm", md: "md", "3xl": "md" }}
                    mb={2}
                    bgGradient="linear(to-r, yellow.400, yellow.400, red.400, red.400)"
                    bgClip="text"
                    _hover={{
                      cursor: "pointer",
                      bgGradient:
                        "linear(to-r, yellow.300, yellow.300, red.300, red.300)",
                    }}
                  >
                    {`${
                      vehicleData.year
                    } ${vehicleData.make.toUpperCase()} ${vehicleData.model.toUpperCase()}`}
                  </Heading>
                  <Divider
                    w={{ base: "100%", lg: "95%" }}
                    mt={2}
                    borderWidth={1}
                    borderColor="borderColor"
                  />
                  <Divider
                    w={{ base: "100%", lg: "95%" }}
                    mt={2}
                    borderWidth={1}
                    borderColor="borderColor"
                  />
                  {/* Average Price */}
                  <Stack
                    w="100%"
                    py={4}
                    px={{ base: 0, lg: 4 }}
                    spacing={4}
                    justifyContent="space-evenly"
                  >
                    <ThreeColumnStatisticBlockLargeText
                      icon={
                        <FaRocket color="white" fontSize={MEDIUM_ICON_SIZE} />
                      }
                      textColor={"#38A169"}
                      iconBackgroundColor={"#38A169"}
                      heading="PRICE PREDICTION"
                      tooltipLabel="Our price prediction for this vehicle"
                    >
                      {vehicleData.prediction < 0
                        ? 100
                        : "$" +
                          (
                            Math.round(vehicleData.prediction / 500) * 500
                          ).toLocaleString()}
                    </ThreeColumnStatisticBlockLargeText>
                    {/* Average Mileage   */}
                    <ThreeColumnStatisticBlockLargeText
                      icon={
                        <FaGavel color="white" fontSize={MEDIUM_ICON_SIZE} />
                      }
                      textColor={"#E53E3E"}
                      iconBackgroundColor={"#E53E3E"}
                      heading={
                        isVehicleLive ? "CURRENT BID PRICE" : "FINAL BID PRICE"
                      }
                      tooltipLabel="The final auction bid price of this vehicle"
                    >
                      {"$" + Math.round(vehicleData.bid_price).toLocaleString()}
                    </ThreeColumnStatisticBlockLargeText>
                    {/* Number of vehicles sold */}
                    <ThreeColumnStatisticBlockLargeText
                      icon={
                        <FaArrowsAltH
                          color="white"
                          fontSize={MEDIUM_ICON_SIZE}
                        />
                      }
                      textColor={SCATTER_PLOT_COLOR}
                      iconBackgroundColor={SCATTER_PLOT_COLOR}
                      heading="EXPECTED PRICE RANGE"
                      tooltipLabel="The estimated price range we think this vehicle will ultimately sell for"
                    >
                      {`${lowPriceEstimate} - ${highPriceEstimate}`}
                    </ThreeColumnStatisticBlockLargeText>
                  </Stack>
                </Box>
              </Stack>
              {/* START STATISTICS */}
              <Stack
                w="100%"
                pt={{ base: 6, md: 8 }}
                pb={4}
                direction={{ base: "column", md: "row" }}
                spacing={{ base: 6, md: 8 }}
              >
                <Box
                  w={{ base: "100%", md: "50%" }}
                  bg="secondaryBackgroundColor"
                  borderRadius={12}
                  borderWidth={1}
                  borderColor="borderColor"
                  py={2}
                  px={4}
                  justify="center"
                  align="center"
                >
                  <Heading
                    pr={0}
                    pl={5}
                    pt={1}
                    size={{ base: "sm", md: "md", "2xl": "md" }}
                    mb={2}
                  >
                    STATISTICS
                  </Heading>
                  <Divider
                    w={{ base: "100%", lg: "95%" }}
                    mt={2}
                    borderWidth={1}
                    borderColor="borderColor"
                  />
                  <Divider
                    w={{ base: "100%", lg: "95%" }}
                    mt={2}
                    borderWidth={1}
                    borderColor="borderColor"
                  />
                  <Stack
                    w="100%"
                    py={4}
                    px={{ base: 0, lg: 4 }}
                    spacing={4}
                    justifyContent="space-evenly"
                  >
                    {/* Average Price   */}
                    <ThreeColumnStatisticBlockLargeText
                      icon={
                        <FaWallet color="#38A169" fontSize={MEDIUM_ICON_SIZE} />
                      }
                      textColor={"#38A169"}
                      iconBackgroundColor={"gray.50"}
                      heading="AVERAGE PRICE"
                      tooltipLabel="The average price of this model/year of vehicle"
                    >
                      {"$" + averagePrice.toLocaleString()}
                    </ThreeColumnStatisticBlockLargeText>
                    {/* Average Mileage */}
                    <ThreeColumnStatisticBlockLargeText
                      icon={
                        <FaRoad color="#E53E3E" fontSize={MEDIUM_ICON_SIZE} />
                      }
                      textColor="#E53E3E"
                      iconBackgroundColor={"gray.50"}
                      heading="AVERAGE MILEAGE"
                      tooltipLabel="The average mileage of this model/year of vehicle"
                    >
                      {`${averageMileage.toLocaleString()}`}
                    </ThreeColumnStatisticBlockLargeText>
                    {/* Vehicle Mileage */}
                    <ThreeColumnStatisticBlockLargeText
                      icon={
                        <FaCar
                          color={SCATTER_PLOT_COLOR}
                          fontSize={MEDIUM_ICON_SIZE}
                        />
                      }
                      textColor={SCATTER_PLOT_COLOR}
                      iconBackgroundColor={"gray.50"}
                      heading="VEHICLE MILEAGE"
                      tooltipLabel="Actual mileage of this vehicle"
                    >
                      {`${vehicleData.mileage?.toLocaleString()}`}
                    </ThreeColumnStatisticBlockLargeText>
                  </Stack>
                </Box>
                {similarModelData.length > 0 ? (
                  <Box w={{ base: "100%", md: "50%" }}>
                    <ChartWrapper
                      heading="Average Price Per Year"
                      chart={AveragePriceVsYearAreaChart}
                      data={
                        similarModelData.filter(
                          (item) => item.year === vehicleData.year
                        ).length < 2
                          ? similarModelData
                          : similarModelData.filter(
                              (item) => item.year === vehicleData.year
                            )
                      }
                      color="#D34C46"
                      labelColor="white"
                      height={350}
                    />
                  </Box>
                ) : (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius={12}
                    borderColor="borderColor"
                    bg="secondaryBackgroundColor"
                    w={{ base: "100%", md: "50%" }}
                    height={{ base: 350, md: "auto" }}
                  >
                    <Stack spacing={4} direction="row">
                      <Spinner size={{ base: "md", lg: "xl" }} color="white" />
                      <Heading size={{ base: "md", lg: "xl" }}>
                        {" "}
                        GRAPH LOADING
                      </Heading>
                    </Stack>
                  </Box>
                )}
              </Stack>
              {/* START WANT MORE DATA SECTION */}
              <Box w="100%" mt="45px" mb="55px">
                <Heading
                  size="lg"
                  pt={4}
                  pb={2}
                  mx="auto"
                  w={{ base: "100%", md: "80%" }}
                >
                  WANT MORE DATA?
                </Heading>
                <Text
                  fontWeight="semibold"
                  fontSize="lg"
                  py={1}
                  mx="auto"
                  w={{ base: "100%", md: "80%" }}
                >
                  Unlock the power of{" "}
                  <Box
                    onClick={() =>
                      handleNavigateToAnalytics(
                        vehicleData.make,
                        vehicleData.model
                      )
                    }
                    as="span"
                    color="redChartTheme"
                    fontWeight="bold"
                    _hover={{
                      color: "red.300",
                      cursor: "pointer",
                    }}
                  >
                    PredictATrailer Analytics
                  </Box>
                  ! Elevate your game with real-time market insights, dive into
                  comprehensive data for every vehicle auctioned on
                  Bring-A-Trailer, and gain a competitive edge that propels you
                  to success! Ready for a data-driven journey? Let's go!
                </Text>
                <Stack
                  spacing={6}
                  pt={6}
                  textAlign="left"
                  direction={{ base: "column", md: "row" }}
                  display={{ base: "none", md: "flex" }}
                >
                  <IconHeaderTextBox
                    width={{ base: "33%", "2xl": "25%" }}
                    icon={<FaRegChartBar fontSize={MEDIUM_ICON_SIZE} />}
                    bgColor="#38A169"
                    heading="TONS OF GRAPHS"
                    text="Explore an extensive collection of insightful graphs and visualizations that allow you track and analyze the ever-changing car market."
                  />
                  <IconHeaderTextBox
                    width={{ base: "33%", "2xl": "25%" }}
                    icon={<FaChartLine fontSize={MEDIUM_ICON_SIZE} />}
                    bgColor="#E53E3E"
                    heading="RECENT PRICE TRENDS"
                    text="Dive into your favorite car's latest price trends and stats, including insights on top-selling colors and model years."
                  />
                  <IconHeaderTextBox
                    width={{ base: "33%", "2xl": "25%" }}
                    icon={<FaSearchDollar fontSize={MEDIUM_ICON_SIZE} />}
                    bgColor={SCATTER_PLOT_COLOR}
                    heading="130,000+ VEHICLES"
                    text="Explore a treasure trove of data with information on over 130,000 vehicles, providing you with a wealth of insights."
                  />
                  <IconHeaderTextBox
                    width={{ base: "33%", "2xl": "25%" }}
                    display={{ base: "none", "2xl": "block" }}
                    icon={<FaCrosshairs fontSize={MEDIUM_ICON_SIZE} />}
                    bgColor={"blue.500"}
                    heading="IN-DEPTH STATISTICS"
                    text="Whether you are looking for general market trends or specific vehicle details, our comprehensive database of in-depth statistics has you covered."
                  />
                </Stack>
                <Box w="100%" pt={{ base: 4, md: 6 }}>
                  <Button
                    width={["100%", "100%", "25%"]}
                    onClick={() =>
                      handleNavigateToAnalytics(
                        vehicleData.make?.toLowerCase(),
                        vehicleData.model?.toLowerCase()
                      )
                    }
                    bg="redChartTheme"
                    color="white"
                    colorScheme={"red"}
                  >
                    Take Me There!
                  </Button>
                </Box>
              </Box>
              {/* START SIMILAR VEHICLES THAT HAVE SOLD */}
              <SoldVehicleInfoContainer
                heading="PREVIOUSLY SOLD VEHICLES"
                url={url}
                vehicleData={similarModelData}
              />
            </>
          )}
        </Container>
      </Box>
    </>
  );
}
