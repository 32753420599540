import {
  Box,
  Button,
  Flex,
  Heading,
  SimpleGrid,
  useBreakpointValue,
} from "@chakra-ui/react";
import { SoldVehicleInfoCard } from "./SoldVehicleInfoCard";
import { useState, useEffect } from "react";

export const SoldVehicleInfoContainer = ({ url, vehicleData, heading }) => {
  const columnSize = useBreakpointValue({
    base: 1,
    sm: 1,
    md: 2,
    xl: 3,
    "2xl": 4,
  });

  const [visibleItems, setVisibleItems] = useState(4);

  // set appropriate number of columns based on user screen size
  useEffect(() => {
    const initialVisibleItems = columnSize === 3 ? 3 : 4;
    setVisibleItems(initialVisibleItems);
  }, [columnSize]);

  // Sort chartData by auction_year in descending order
  const sortedVehicleData = vehicleData.sort(
    (a, b) => b.auction_year - a.auction_year
  );

  const handleLoadMore = () => {
    const iterator = columnSize === 3 ? 3 : 4;

    if (visibleItems + iterator > vehicleData.length) {
      setVisibleItems(vehicleData.length);
    } else {
      setVisibleItems((prevVisibleItems) => prevVisibleItems + iterator);
    }
  };

  return (
    <>
      <Box
        margin="auto"
        pl={{ base: 0, md: 1 }}
        justifyContent="center"
        align="center"
        w="100%"
        maxW="100%"
        pb={8}
      >
        <Heading size={{ base: "md", md: "lg" }} pt={4} pb={8}>
          {heading}
        </Heading>

        <SimpleGrid
          columns={columnSize}
          spacing={{ base: 8, md: 12, "2xl": 8 }}
        >
          {sortedVehicleData
            .filter((row) => !url || row.url !== url)
            .slice(0, visibleItems)
            .map((row, index) => (
              <SoldVehicleInfoCard
                key={"sold-vehicle-card-" + index}
                vehicleData={row}
              />
            ))}
        </SimpleGrid>
      </Box>
      {visibleItems < vehicleData.length && (
        <Flex justifyContent="center">
          <Button
            color="white"
            colorScheme="blue"
            variant="outline"
            borderColor="borderColor"
            onClick={handleLoadMore}
            w={{ base: "90%", md: "40%", lg: "20%" }}
            py={4}
          >
            Load More
          </Button>
        </Flex>
      )}
    </>
  );
};
