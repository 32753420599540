import { Box, Heading, Stack, Text } from "@chakra-ui/react";
import { IconCircle } from "./IconCircle";

export const IconHeaderTextBox = ({
  icon,
  bgColor,
  heading,
  text,
  width,
  display,
}) => {
  return (
    <>
      <Box
        bg="secondaryBackgroundColor"
        p={4}
        borderRadius={12}
        borderWidth={1}
        borderColor="borderColor"
        display={display}
        w={width}
      >
        <Stack w="100%" justify="space-evenly" align="flex-start">
          <IconCircle icon={icon} bgColor={bgColor} />
          <Heading pt={4} size={{ base: "xs", md: "sm", "2xl": "md" }}>
            {heading}
          </Heading>
          <Text
            color="gray.300"
            textAlign="left"
            fontSize={{ base: "md", md: "lg" }}
          >
            {text}
          </Text>
        </Stack>
      </Box>
    </>
  );
};
