import { Stack, Box, Heading, Text } from "@chakra-ui/react";
import { IconSquare } from "./IconSquare";
import { IconToolTip } from "./IconToolTip";
import theme from "../Theme/theme";

export const ThreeColumnStatisticBlock = ({
  children,
  icon,
  tooltipLabel,
  heading,
  iconBackgroundColor,
  textColor,
}) => {
  return (
    <Stack
      w="100%"
      spacing={0}
      direction="row"
      justifyContent="space-around"
      bg={{
        base: `linear-gradient(to right, #2C3236, ${theme.colors.secondaryBackgroundColor}, #2C3236)`,
      }}
      borderRadius={12}
      py={3}
    >
      <Box alignSelf="center">
        {<IconSquare bgColor={iconBackgroundColor} icon={icon} />}
      </Box>
      <Stack
        w="50%"
        alignSelf="flex-start"
        justifySelf="center"
        mx="auto"
        py={2}
      >
        <Heading size={{ base: "xs", "2xl": "sm" }}>{heading}</Heading>
        <Text
          color={textColor}
          fontWeight="bold"
          fontSize={{ base: "sm", "2xl": "md" }}
        >
          {children}
        </Text>
      </Stack>
      <IconToolTip label={tooltipLabel} />
    </Stack>
  );
};
