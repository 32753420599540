import {
  Avatar,
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon, ChevronRightIcon } from "@chakra-ui/icons";
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

const NAV_ITEMS = [
  {
    label: "Home",
    href: "/",
  },
  {
    label: "Analytics",
    href: "/analytics",
  },
  {
    label: "About",
    href: "/about",
  },
  {
    label: "Contact",
    href: "/contact",
  },
  // {
  //   label: 'Find Work',
  //   children: [
  //     {
  //       label: 'Job Board',
  //       subLabel: 'Find your dream design job',
  //       href: '#',
  //     },
  //     {
  //       label: 'Freelance Projects',
  //       subLabel: 'An exclusive list for contract work',
  //       href: '#',
  //     },
  //   ],
  // },
];

export default function Navbar() {
  const { isOpen, onToggle } = useDisclosure();
  const { user, isAuthenticated, isLoading, loginWithRedirect, logout } =
    useAuth0();
  const navigate = useNavigate();

  function handleMyAccountClick() {
    navigate("/profile");
  }

  const handleLogin = () => {
    localStorage.setItem("returnTo", window.location.href);
    loginWithRedirect();
    // loginWithRedirect({ appState: { target: window.location.href } });
  };

  return (
    <Box>
      <Flex
        bg="backgroundColor"
        color={useColorModeValue("gray.600", "white")}
        minH={"3vh"}
        maxW="100vw"
        overflow="hidden"
        py={{ base: 4 }}
        px={{ base: 8 }}
        align={"center"}
      >
        <Flex
          flex={{ base: 1, md: "auto" }}
          ml={{ base: -2 }}
          display={{ base: "flex", md: "none" }}
        >
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={"ghost"}
            aria-label={"Toggle Navigation"}
          />
        </Flex>
        <Flex
          flex={{ base: 1 }}
          align="center"
          justify={{ base: "center", md: "start" }}
        >
          <Link href={"/"}>
            <HStack spacing={0}>
              <Text
                fontSize={"xl"}
                textAlign={useBreakpointValue({ base: "center", md: "left" })}
                fontFamily="Blanka"
                as="b"
                color="white"
              >
                Predict
              </Text>
              <Text
                fontSize={"2xl"}
                textAlign={useBreakpointValue({ base: "center", md: "left" })}
                fontFamily="Blanka"
                as="b"
                color="redChartTheme"
              >
                A
              </Text>
              <Text
                fontSize={"xl"}
                textAlign={useBreakpointValue({ base: "center", md: "left" })}
                fontFamily="Blanka"
                as="b"
                color="white"
              >
                Trailer
              </Text>
            </HStack>
          </Link>

          <Flex display={{ base: "none", md: "flex" }} ml={10} fontSize={"xl"}>
            <DesktopNav />
          </Flex>
        </Flex>

        <Stack
          flex={{ base: 1, md: 0 }}
          justify={"flex-end"}
          align="center"
          direction={"row"}
          spacing={6}
        >
          {isAuthenticated && !isLoading ? (
            <>
              <Box>
                <Menu>
                  <MenuButton
                    variant="link"
                    rounded="full"
                    as={IconButton}
                    icon={
                      <Avatar
                        bg="blue.600"
                        name={user.name}
                        // src="https://bit.ly/broken-link"
                      />
                    }
                  />
                  <MenuList>
                    <MenuItem onClick={handleMyAccountClick}>
                      My Account
                    </MenuItem>
                    <MenuDivider />
                    <MenuItem
                      onClick={() =>
                        logout({
                          logoutParams: { returnTo: window.location.origin },
                        })
                      }
                    >
                      Logout
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Box>
              <Box>
                {/* <Avatar bg="blue.600" name={user.name} src='https://bit.ly/broken-link' /> */}
                <Button
                  as="a"
                  display={{ base: "none", md: "inline-flex" }}
                  fontSize={"md"}
                  fontWeight={600}
                  color={"white"}
                  bg={"redChartTheme"}
                  onClick={() =>
                    logout({
                      logoutParams: { returnTo: window.location.origin },
                    })
                  }
                  _hover={{
                    bg: "red.300",
                  }}
                >
                  Sign Out
                </Button>
              </Box>
            </>
          ) : (
            <>
              <Button
                as={"a"}
                fontSize={"md"}
                fontWeight={400}
                variant={"link"}
                // onClick={() =>
                //   loginWithRedirect({
                //     appState: { target: window.location.href },
                //   })
                // }
                // onClick={() => loginWithRedirect()}
                onClick={() => handleLogin()}
                // href={'/pricing'}
              >
                Sign In
              </Button>
              <Button
                as="a"
                display={{ base: "none", md: "inline-flex" }}
                fontSize={"md"}
                fontWeight={600}
                color={"white"}
                bg={"redChartTheme"}
                // onClick={() =>
                //   loginWithRedirect({
                //     appState: { target: window.location.href },
                //   })
                // }
                // onClick={() => loginWithRedirect()}
                onClick={() => handleLogin()}
                // href={'/pricing'}
                _hover={{
                  bg: "red.300",
                }}
              >
                Sign Up
              </Button>
            </>
          )}
        </Stack>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  );
}

const DesktopNav = () => {
  const linkColor = useColorModeValue("gray.600", "gray.200");
  const linkHoverColor = useColorModeValue("gray.800", "white");
  const popoverContentBgColor = useColorModeValue("white", "gray.800");

  return (
    <Stack direction={"row"} spacing={8}>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={"hover"} placement={"bottom-start"}>
            <PopoverTrigger>
              <Link
                p={2}
                href={navItem.href ?? "#"}
                fontSize={"md"}
                fontWeight={500}
                color={linkColor}
                _hover={{
                  textDecoration: "none",
                  color: linkHoverColor,
                }}
              >
                {navItem.label}
              </Link>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={"xl"}
                bg={popoverContentBgColor}
                p={4}
                rounded={"xl"}
                minW={"sm"}
              >
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

// const DesktopSubNav = ({ label, href, subLabel }: NavItem) => {
const DesktopSubNav = ({ label, href, subLabel }) => {
  return (
    <Link
      href={href}
      role={"group"}
      display={"block"}
      p={2}
      rounded={"md"}
      _hover={{ bg: useColorModeValue("red.500", "gray.900") }}
    >
      <Stack direction={"row"} align={"center"}>
        <Box>
          <Text
            transition={"all .3s ease"}
            _groupHover={{ color: "red.400" }}
            fontWeight={500}
          >
            {label}
          </Text>
          <Text fontSize={"sm"}>{subLabel}</Text>
        </Box>
        <Flex
          transition={"all .3s ease"}
          transform={"translateX(-10px)"}
          opacity={0}
          _groupHover={{ opacity: "100%", transform: "translateX(0)" }}
          justify={"flex-end"}
          align={"center"}
          flex={1}
        >
          <Icon color={"red.400"} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Link>
  );
};

const MobileNav = () => {
  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      p={4}
      display={{ md: "none" }}
    >
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

// const MobileNavItem = ({ label, children, href }: NavItem) => {
const MobileNavItem = ({ label, children, href }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as={Link}
        href={href ?? "#"}
        justify={"space-between"}
        align={"center"}
        _hover={{
          textDecoration: "none",
        }}
      >
        <Text
          fontWeight={600}
          color={useColorModeValue("gray.600", "gray.200")}
        >
          {label}
        </Text>
        {/* {children && (
          <Icon
            as={ChevronDownIcon}
            transition={'all .25s ease-in-out'}
            transform={isOpen ? 'rotate(180deg)' : ''}
            w={6}
            h={6}
          />
        )} */}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.700")}
          align={"start"}
        >
          {children &&
            children.map((child) => (
              <Link key={child.label} py={2} href={child.href}>
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};
