/**
 * Finds the color with the highest average price and the color with the lowest average price
 * based on provided data and common color list.
 *
 * @param {Array} data - An array of objects containing color and bid_price.
 * @returns {Object} An object with properties 'high' and 'low' representing
 * the color with the highest and lowest average prices respectively.
 */
const INITIAL_YEAR = 1900;
const CURRENT_YEAR = parseInt(new Date().getFullYear());

export function findHighestAndLowestAvgModelYear(data) {
  const yearCounts = {};
  const yearTotalPrices = {};

  if (!data || data.length === 0) {
    const result = {
      high: { year: INITIAL_YEAR, price: 0 },
      low: { year: CURRENT_YEAR, price: 0 },
    };

    return result;
  }

  data.forEach((entry) => {
    if (
      entry.year &&
      (typeof entry.year === "string" || typeof entry.year === "number")
    ) {
      const year = parseInt(entry.year);
      if (year > 1900 && year < parseInt(new Date().getFullYear())) {
        yearCounts[year] = (yearCounts[year] || 0) + 1;
        yearTotalPrices[year] = (yearTotalPrices[year] || 0) + entry.bid_price;
      } else {
        yearCounts["Misc."] = (yearCounts["Misc."] || 0) + 1;
        yearTotalPrices["Misc."] =
          (yearTotalPrices["Misc."] || 0) + entry.bid_price;
      }
    } else {
      yearCounts["Misc."] = (yearCounts["Misc."] || 0) + 1;
      yearTotalPrices["Misc."] =
        (yearTotalPrices["Misc."] || 0) + entry.bid_price;
    }
  });

  const yearAvgPrices = {};

  for (const year in yearCounts) {
    yearAvgPrices[year] = yearTotalPrices[year] / yearCounts[year];
  }

  const highestAvgYear = Object.keys(yearAvgPrices).reduce((a, b) =>
    yearAvgPrices[a] > yearAvgPrices[b] ? a : b
  );

  const lowestAvgYear = Object.keys(yearAvgPrices).reduce((a, b) =>
    yearAvgPrices[a] < yearAvgPrices[b] ? a : b
  );

  const result = {
    high: {
      year: highestAvgYear,
      price: Math.round(yearAvgPrices[highestAvgYear]),
    },
    low: {
      year: lowestAvgYear,
      price: Math.round(yearAvgPrices[lowestAvgYear]),
    },
  };

  return result;
}
