import { Divider, Heading, Stack } from "@chakra-ui/react";
import React from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
} from "recharts";
import { tooltipStyles } from "./ChartFormats/tooltipStyle";

export default function SalesByYearBarChart(props, includeReserveNotMet) {
  // Whether to include RNM auction values or not
  includeReserveNotMet = includeReserveNotMet || true;

  // format price to $140k or $1.4M
  const currencyLabelFormatter = (value) => {
    if (value >= 1000000) {
      return "$" + (value / 1000000).toFixed(1) + "M";
    } else {
      return "$" + parseInt(value / 1000) + "k";
    }
  };

  const calculateAveragePrices = (iterable) => {
    // Filter the data based on whether to include reserve not met or not
    let filteredData = iterable;

    if (!includeReserveNotMet) {
      filteredData = iterable.filter((item) => item.sold === "True");
    }

    const yearCounts = {};
    const yearTotalPrices = {};

    filteredData.forEach((entry) => {
      if (
        entry.year &&
        (typeof entry.year === "string" || typeof entry.year === "number")
      ) {
        const year = parseInt(entry.year);
        if (year > 1900 && year < parseInt(new Date().getFullYear())) {
          yearCounts[year] = (yearCounts[year] || 0) + 1;
          yearTotalPrices[year] =
            (yearTotalPrices[year] || 0) + entry.bid_price;
        } else {
          yearCounts["Misc."] = (yearCounts["Misc."] || 0) + 1;
          yearTotalPrices["Misc."] =
            (yearTotalPrices["Misc."] || 0) + entry.bid_price;
        }
      } else {
        yearCounts["Misc."] = (yearCounts["Misc."] || 0) + 1;
        yearTotalPrices["Misc."] =
          (yearTotalPrices["Misc."] || 0) + entry.bid_price;
      }
    });

    const yearAvgPrices = {};

    for (const year in yearCounts) {
      yearAvgPrices[year] = yearTotalPrices[year] / yearCounts[year];
    }

    return yearAvgPrices;
  };

  // Format to be ingested into chart
  const years = Object.entries(calculateAveragePrices(props.data)).map(
    ([year, avgPrice]) => ({
      year: year,
      avgPrice: avgPrice,
    })
  );

  // Create custom tooltip
  const formatTooltip = (value) => {
    return `$${value.toLocaleString()}`;
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      const yearData = payload[0].payload;
      const { avgPrice } = yearData;

      return (
        <Stack className="custom-tooltip">
          <Heading size="md" color="#D3A346">{`Year: ${label}`}</Heading>
          <Divider borderColor="gray.400" />
          <Heading size="md" color={props.color}>{`Avg. Price: ${Math.round(
            avgPrice
          ).toLocaleString()}`}</Heading>
        </Stack>
      );
    }

    return null;
  };

  // Define a gradient configuration
  const salesGradientId = "yearGradient";
  const salesGradientColorStart = "#D3A346"; // Start color of gradient
  const salesGradientColorEnd = "#D3A346"; // End color of gradient

  return (
    <ResponsiveContainer
      width="100%"
      height={props.height ? props.height : 350}
    >
      <BarChart
        data={years}
        margin={{
          top: 20,
          right: 15,
          bottom: 18,
          left: 15,
        }}
      >
        <defs>
          {/* Define the linear gradient */}
          <linearGradient
            id={salesGradientId}
            x1="0%"
            y1="0%"
            x2="0%"
            y2="100%"
          >
            <stop offset="0%" stopColor={salesGradientColorStart} />
            <stop offset="5%" stopColor={salesGradientColorEnd} />
            <stop
              offset="100%"
              stopColor={salesGradientColorEnd}
              stopOpacity="0"
            />
          </linearGradient>
        </defs>
        <XAxis
          name="year"
          dataKey="year"
          stroke={props.labelColor}
          interval={0}
          tick={{ fontSize: 12 }}
          dy={5}
          angle={-45} // Rotate the x-axis labels
          textAnchor="end" // Align the rotated labels
        ></XAxis>
        <YAxis
          name="avgPrice"
          stroke={props.labelColor}
          tickFormatter={currencyLabelFormatter}
        ></YAxis>
        <CartesianGrid vertical={false} strokeDasharray="3 3" />
        <Tooltip
          formatter={formatTooltip}
          content={<CustomTooltip />}
          wrapperStyle={tooltipStyles}
        />
        <Bar
          name="Average Price"
          dataKey="avgPrice"
          fill={`url(#${salesGradientId})`} // Use the gradient
          isAnimationActive={false}
        />
      </BarChart>
    </ResponsiveContainer>
  );
}
